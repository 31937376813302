import useGeocode from '@/hooks/useGeocode';
import usePlacesAutocomplete from '@/hooks/usePlacesAutocomplete';
import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ToggleLabelInput from '../ToggleLabelInput';
import { MODAL_STEPS } from './config';

const PlaceSearch = ({
    country,
    value,
    onChange,
    error,
    isInternalDropDown,
    onSearchComplete,
    isDataPopulated,
    handleChange,
    shouldSearch,
    setShouldSearch,
    goToNextStep,
}) => {
    const t = useTranslations('LocationSelector');
    const [searchText, setSearchText] = useState('');

    const { geocode } = useGeocode();

    const { suggestions, clearSuggestions } = usePlacesAutocomplete({
        input: searchText,
        skip: !shouldSearch || isDataPopulated,
        initialOptions: {
            includedRegionCodes: country?.isoCode,
        },
    });

    useEffect(() => {
        if (!shouldSearch) {
            clearSuggestions();
            setSearchText('');
        }
    }, [shouldSearch]);

    useEffect(() => {
        setSearchText(value?.formattedAddress || '');
    }, [value?.formattedAddress]);

    useEffect(() => {
        if (onSearchComplete && Array.isArray(suggestions)) {
            onSearchComplete(suggestions);
        }
    }, [suggestions, onSearchComplete]);

    const handleOptionSelect = async (suggestion) => {
        if (!suggestion?.placeId) return;
        setShouldSearch(false);
        clearSuggestions();

        const response = await geocode({ place_id: suggestion.placeId });
        onChange({
            ...suggestion,
            coordinates: response.geometry.location,
            formattedAddress: response.formatted_address,
        });
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setSearchText(newValue);
        setShouldSearch(true);
        handleChange('isDataPopulated', false);
        goToNextStep(MODAL_STEPS.SEARCH_LOCATION);
    };

    return (
        <>
            <ToggleLabelInput
                containerClassName="input-group"
                inputClassName="formControl"
                labelClassName="labelClass"
                value={searchText}
                hasSearchIcon
                onChange={handleInputChange}
                placeholder={t('searchLocation')}
            />
            {!isDataPopulated && isInternalDropDown && suggestions.length > 0 && (
                <div className="recent-search place-search">
                    <h4>{t('searchResult')}</h4>
                    <div className="recent-search-list">
                        {suggestions.map((suggestion) => (
                            <button key={suggestion?.text?.text} onClick={() => handleOptionSelect(suggestion)}>
                                {suggestion?.text?.text}
                            </button>
                        ))}
                    </div>
                </div>
            )}
            {error && <span className="error">{error}</span>}
        </>
    );
};

export default PlaceSearch;

PlaceSearch.propTypes = {
    onSearchComplete: PropTypes.func,
    country: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    goToNextStep: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    isInternalDropDown: PropTypes.bool,
    isDataPopulated: PropTypes.bool,
    shouldSearch: PropTypes.bool,
    setShouldSearch: PropTypes.func.isRequired,
};
