import PropTypes from 'prop-types';
import Link from 'next/link';
function NavigationList({
  title,
  items,
  routes,
  activeRoute
}) {
  return <ul data-sentry-component="NavigationList" data-sentry-source-file="NavigationList.jsx">
            <li>
                <h4>{title}</h4>
            </li>
            {items.map(item => <li key={item}>
                    <Link className={activeRoute === routes[item] ? 'footer-item-active' : ''} href={routes[item] || '/'}>
                        {item}
                    </Link>
                </li>)}
        </ul>;
}
NavigationList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  routes: PropTypes.object.isRequired,
  activeRoute: PropTypes.string.isRequired
};
export default NavigationList;