import { localStorageKeys } from '@/configs/constants';
import { auth } from '@/configs/firebase.config';
import useLocalStorage from '@/hooks/useLocalStorage';
import useLocationOnboarding from '@/hooks/useLocationOnboarding';
import { getDocument } from '@/lib/firebase/query';
import ComingSoon from '@/pages/ComingSoon';
import { setAccount } from '@/redux/slices/account';
import { setAuthData } from '@/redux/slices/auth';
import { addHomeData, addLoader, removeLoader, setCountries } from '@/redux/slices/common';
import { updateCurrentOrder } from '@/redux/slices/order';
import { setCurrentStore, setNearByStores } from '@/redux/slices/stores';
import { getCountries, getHomeData, getNearByStores, getNearestStore } from '@/service/home.service';
import { isFalsy } from '@/utils';
import { collections } from '@/utils/constants/fb.collections';
import { onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import { PropTypes } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonFooter from '../footer/CommonFooter';
import Footer from '../footer/Footer';
import Header from '../header/header';
import PolicyHeader from '../header/PolicyHeader';
import AppStoreOrPlayStoreRedirect from '../redirectForMobile/AppStoreOrPlayStoreRedirect ';
const MainLayout = ({
  children
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    query
  } = useRouter();
  const blockOtherRoutesForMobile = process.env.NEXT_PUBLIC_BLOCK_FOR_MOBILE;
  const isAuthRoute = router.route.includes('/auth');
  const isPolicyRoute = router.route.includes('/footerItems');
  const isProductLinkSharedRoute = query.modalType === 'productDetails';
  const isPreloadedRef = useRef(false);
  const [isPreloadComplete, setIsPreloadComplete] = useState(false);
  const accountData = useSelector(store => store.account)?.account;
  const orderData = useSelector(store => store.order)?.order;
  useLocationOnboarding();
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const selectedOrderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
  useEffect(() => {
    getCountriesData();
    const {
      isLocationAllowed
    } = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION) || {};
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        getCurrentUser({
          isDeviceLocationAllowed: !!isLocationAllowed,
          authData: user
        });
      }
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (typeof window === 'undefined' || isPreloadedRef.current) {
      return;
    }
    const selectedCountry = actions.getNestedValue(localStorageKeys.SELECTED_COUNTRY);
    const deviceLocation = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION);
    const selectedAddress = actions.getNestedValue(localStorageKeys.SELECTED_ADDRESS);
    dispatch(setAccount({
      deviceLocation,
      selectedCountry
    }));
    dispatch(updateCurrentOrder(selectedAddress));
    isPreloadedRef.current = true;
    setIsPreloadComplete(true);
  }, []);
  useEffect(() => {
    if (!isPreloadComplete) return;
    if (!isFalsy(accountData) || !isFalsy(orderData)) {
      const orderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
      if (orderType) {
        fetchHomeData(accountData?.selectedCountry?.id, orderType);
      }
    }
    const selectedLocation = getSelectedLocation();
    if (!isFalsy(selectedLocation)) {
      getNearestStoreData(selectedLocation);
      getNearByStoresData(selectedLocation);
    }
  }, [isPreloadComplete, accountData, orderData]);
  const getCountriesData = async () => {
    try {
      const response = await getCountries();
      const formatted = response.map(({
        iso,
        ...country
      }) => ({
        ...country,
        isoCode: iso
      }));
      dispatch(setCountries(formatted));
    } catch (e) {
      console.error('Error listing countries:', e);
    }
  };
  const getSelectedLocation = () => {
    let location = {};
    if (accountData?.deviceLocation?.data) {
      location = accountData?.deviceLocation?.data;
    }
    if (orderData?.address?.geoData?.coordinates) {
      location = orderData?.address?.geoData?.coordinates;
    }
    return location;
  };
  const getNearByStoresData = async (selectedLocation = {}) => {
    if (Object.keys(selectedLocation || {}).length) {
      try {
        dispatch(addLoader('getNearByStoresData'));
        const response = await getNearByStores({
          ...selectedLocation,
          type: selectedOrderType?.replace('GLOBAL_', '')
        });
        dispatch(setNearByStores(response?.data));
        return response;
      } catch (error) {
        console.error('error', error);
      } finally {
        dispatch(removeLoader('getNearByStoresData'));
      }
    }
  };
  const getNearestStoreData = async (selectedLocation = {}) => {
    if (Object.keys(selectedLocation || {}).length) {
      try {
        dispatch(addLoader('getNearestStoreData'));
        const response = await getNearestStore({
          ...selectedLocation,
          type: selectedOrderType?.replace('GLOBAL_', '')
        });
        dispatch(setCurrentStore(response?.data));
        return response;
      } catch (error) {
        console.error('error', error);
      } finally {
        dispatch(removeLoader('gestNearestStoreData'));
      }
    }
  };
  const fetchHomeData = async (country, orderType) => {
    if (!country) return;
    const {
      home,
      companyDetails
    } = await getHomeData(country, orderData, orderType);
    if (home) {
      dispatch(addHomeData({
        home,
        companyDetails
      }));
    }
  };
  const getCurrentUser = async ({
    isDeviceLocationAllowed,
    authData
  }) => {
    if (!authData?.uid) {
      return;
    }
    try {
      dispatch(addLoader('getCurrentUser'));
      const response = await getDocument(collections.customers, authData.uid);
      if (response) {
        const authData = {
          ...response,
          isDeviceLocationAllowed,
          isUserLoggedIn: true
        };
        dispatch(setAuthData(authData));
      }
      dispatch(removeLoader('getCurrentUser'));
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  function renderContent() {
    // Handle mobile blocked routes
    if (blockOtherRoutesForMobile && !isProductLinkSharedRoute) {
      // Show policy layout for policy routes
      if (isPolicyRoute) {
        return <>
                        <PolicyHeader />
                        {children}
                        <CommonFooter />
                    </>;
      }
      // Show ComingSoon for all other mobile blocked routes
      return <ComingSoon />;
    } else if (blockOtherRoutesForMobile && isProductLinkSharedRoute) {
      return <AppStoreOrPlayStoreRedirect />;
    }

    // Default layout for non-blocked routes
    return <>
                {!isAuthRoute && <Header />}
                {children}
                {!isAuthRoute && <Footer />}
            </>;
  }
  return <>{renderContent()}</>;
};
export default MainLayout;
MainLayout.propTypes = {
  children: PropTypes.element
};