// cart/index.js
import { localStorageKeys, Operation, ORDER_TYPES } from '@/configs/constants';
import { firestore } from '@/configs/firebase.config';
import cart from '@/utils/constants/cart';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import { getDistanceFromLatLonInKm, transformTimestamps } from '@/utils/index';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    runTransaction,
    serverTimestamp,
    where,
    orderBy,
} from 'firebase/firestore';
import { createProductDescription, simplifyProductModGroups } from './utility';
import { isDelivery } from '@/utils/validations';

/**
 * Adds or updates a product in the cart for a customer in Firestore.
 *
 * @param {Object} details - The product details object.
 * @param {Array} quantities - The array of quantities for each modifier group and its modifiers.
 * @param {Object} orderData - Information related to the order.
 * @param {Object} account - Account information including the selected country.
 * @returns {Object} - An object indicating success or failure.
 */
export const handleCart = async (cartData) => {
    try {
        const {
            details,
            quantities,
            orderData,
            authData,
            inAHurry,
            isMixed,
            remarks,
            storeData,
            locale = 'en',
        } = cartData;
        const customerId = authData?.uid;
        const cartDocPath = `${collections.customers}/${customerId}/${collections.carts}/${customerId}`;
        const foodLinesPath = `${cartDocPath}/${subCollections.foodLines}`;

        // Start Firestore transaction
        await runTransaction(firestore, async (transaction) => {
            const cartDocRef = doc(firestore, cartDocPath);
            const cartSnapshot = await transaction.get(cartDocRef);
            let unitPrice = details.price;
            if (details?.customizable) {
                let additionalCharges = calculateAdditionalCharges(details?.productModGroups, quantities);

                // Update unit price with additional charges
                unitPrice += additionalCharges;
            }
            // Calculate total amount
            const amount = unitPrice * details.productQuantity;
            if (!cartSnapshot.exists()) {
                // Create a new cart if it doesn't exist
                await createCart(transaction, cartDocRef, {
                    details,
                    amount,
                    orderData,
                    authData,
                    storeData,
                    locale,
                });
            } else {
                // Update the existing cart
                await updateCart(transaction, cartDocRef, cartSnapshot, details, amount);
            }

            // Handle food lines
            await handleFoodLines(transaction, foodLinesPath, {
                details,
                quantities,
                unitPrice,
                inAHurry,
                isMixed,
                amount,
                remarks,
                customerId,
            });
        });

        return { success: true };
    } catch (error) {
        console.error('Error adding/updating cart:', error);
        return { success: false, error: error.message || error }; // Return a clearer error message
    }
};

/**
 * Calculates additional charges based on modifiers.
 *
 * @param {Array} productModGroups - The array of modifier groups for the product.
 * @param {Array} quantities - The array of quantities for each modifier group.
 * @returns {number} - The total additional charges.
 */
const calculateAdditionalCharges = (productModGroups, quantities) => {
    return productModGroups.reduce((total, group, groupIndex) => {
        return (
            total +
                group.modifiers?.reduce((sum, modifier, modifierIndex) => {
                    const quantity = quantities[groupIndex]?.[modifierIndex] || 0;
                    return sum + (modifier.price > 0 ? modifier.price * quantity : 0);
                }, 0) || 0
        );
    }, 0);
};

/**
 * Creates a new cart document in Firestore.
 *
 * @param {Object} transaction - Firestore transaction object.
 * @param {Object} cartDocRef - Reference to the cart document.
 * @param {Object} details - The product details object.
 * @param {number} unitPrice - The unit price of the product.
 * @param {number} totalAmount - The total amount including tax.
 * @param {number} totalTax - The total tax amount.
 * @param {Object} orderData - Information related to the order.
 */
const createCart = async (transaction, cartDocRef, { details, amount, orderData, authData, storeData, locale }) => {
    const totalDistance = getDistanceFromLatLonInKm(
        orderData?.address?.geoData?.coordinates?.lat,
        orderData?.address?.geoData?.coordinates?.lng,
        storeData?.currentStore?.location?.coordinates[1],
        storeData?.currentStore?.location?.coordinates[0]
    );
    const cartData = {
        channel: cart.channel,
        count: details.productQuantity,
        createdOn: serverTimestamp(),
        customer: {
            address: {
                name: `${authData?.firstName} ${authData?.lastName}`,
                phone: authData?.phoneNumber,
                geoData: {
                    coordinates: {
                        lat: isDelivery(orderData.type) ? (orderData?.address?.geoData?.coordinates?.lat ?? null) : 0,
                        lng: isDelivery(orderData.type) ? (orderData?.address?.geoData?.coordinates?.lng ?? null) : 0,
                    },
                    formattedAddress: isDelivery(orderData.type)
                        ? (orderData?.address?.geoData?.formattedAddress ?? null)
                        : null,
                },
                addressId: isDelivery(orderData.type) ? (orderData?.id ?? null) : null,
                apartment: isDelivery(orderData.type) ? (orderData?.address?.apartment ?? null) : null,
                formatted: isDelivery(orderData.type) ? (orderData?.address?.geoData?.formattedAddress ?? null) : null,
                directions: isDelivery(orderData.type) ? (orderData?.address?.directions ?? null) : null,
                addressType: isDelivery(orderData.type) ? (orderData?.address?.addressType ?? null) : null,
                houseNumber: isDelivery(orderData.type) ? (orderData?.address?.houseNumber ?? null) : null,
            },
            customerId: authData?.uid || '',
            firstName: authData?.firstName || '',
            lastName: authData?.lastName || '',
        },
        distance: Number(totalDistance.toFixed(2)),
        config: orderData?.companyData,
        lang: locale,
        promiseTime: storeData?.currentStore?.promise || cart.promise_time,
        region: '',
        outletCode: orderData?.outletCode,
        store: {
            name: storeData?.currentStore?.name,
            address: storeData?.currentStore?.address,
            outletCode: orderData?.outletCode,
            companyId: orderData.companyId,
            storeId: orderData.storeId,
            location: {
                lat: storeData?.currentStore?.location?.coordinates[1],
                lng: storeData?.currentStore?.location?.coordinates[0],
            },
            paymentTypes: orderData?.paymentTypes,
            open: storeData?.currentStore?.open,
            close: storeData?.currentStore?.close,
            scheduleTime: orderData?.order?.scheduledTime || '',
        },
        paymentMode: null,
        service: orderData.type,
        source: cart.source,
        itemTotal: amount,
        remarks: '',
    };
    transaction.set(cartDocRef, cartData);
};

/**
 * Updates an existing cart document in Firestore.
 *
 * @param {Object} transaction - Firestore transaction object.
 * @param {Object} cartSnapshot - Snapshot of the existing cart document.
 * @param {Object} details - The product details object.
 * @param {number} unitPrice - The unit price of the product.
 * @param {number} amount - The amount for the new product added.
 * @param {number} totalTax - The total tax amount.
 */
const updateCart = async (transaction, cartDocRef, cartSnapshot, details, amount) => {
    const existingCartData = cartSnapshot.data();
    const newCount = existingCartData.count + details.productQuantity;
    const newItemTotal = existingCartData.itemTotal + amount;

    // Update the cart document in the transaction
    transaction.update(cartDocRef, {
        count: newCount,
        itemTotal: newItemTotal,
    });
};

/**
 * Handles food lines in the cart by either updating existing lines or adding new ones.
 *
 * @param {Object} transaction - Firestore transaction object.
 * @param {string} foodLinesPath - Path to the food lines collection.
 * @param {Object} details - The product details object.
 * @param {Array} quantities - The array of quantities for each modifier group and its modifiers.
 * @param {number} totalAmount - The total amount including tax.
 * @param {number} totalTax - The total tax amount.
 * @param {number} unitPrice - The unit price of the product.

 */
const handleFoodLines = async (transaction, foodLinesPath, foodLineInfo) => {
    const { details, quantities, unitPrice, inAHurry, isMixed, amount, remarks, customerId } = foodLineInfo;
    const productDescription = createProductDescription(details, quantities);
    const simplifiedModGroups =
        details?.customizable === true
            ? simplifyProductModGroups(details?.productModGroups, quantities, isMixed) || []
            : [];

    const foodLineData = {
        createdAt: serverTimestamp(),
        customerId: customerId,
        additionalChargeComponents: '',
        discountComponents: [],
        item: simplifiedModGroups,
        quantity: details.productQuantity,
        unitPrice: unitPrice,
        amount: amount,
        productDescription: productDescription,
        posId: details.posId,
        details: {
            id: details.id,
            name: details.name,
            price: details.price,
            desc: details.description,
            categoryId: details.categoryId,
            image: details.image,
            type: details.type,
        },
        remarks,
        inHurry: inAHurry,
    };

    const foodLinesCollectionRef = collection(firestore, foodLinesPath);
    const foodLineQuery = query(foodLinesCollectionRef, where('productDescription', '==', productDescription));
    const foodLineSnapshot = await getDocs(foodLineQuery);

    if (!foodLineSnapshot.empty) {
        // If an existing document is found, update its quantity and recalculate total amount and tax
        foodLineSnapshot.forEach((doc) => {
            const existingData = doc.data();
            const newQuantity = existingData.quantity + details.productQuantity;
            const newAmount = unitPrice * newQuantity;

            // Recalculate tax components and total tax based on new total quantity and amount

            transaction.update(doc.ref, {
                quantity: newQuantity,
                amount: newAmount,
            });
        });
    } else {
        // If no document is found, create a new food line document
        const newFoodLineDocRef = doc(collection(firestore, foodLinesPath));
        transaction.set(newFoodLineDocRef, foodLineData);
    }
};

/**
 * Fetches all food line items in the cart for a specific customer from Firestore.
 *
 * @param {string} customerId - The ID of the customer.
 * @returns {Object} - An object containing an array of food line items or an error message.
 */

export const listenToCartFoodLines = (customerId, callback) => {
    try {
        const cartDocRef = doc(firestore, `${collections.customers}/${customerId}/${collections.carts}/${customerId}`);
        const foodLinesCollectionRef = collection(
            firestore,
            `${collections.customers}/${customerId}/${collections.carts}/${customerId}/${subCollections.foodLines}`
        );

        // Listen to the cart document
        const cartUnsubscribe = onSnapshot(cartDocRef, (cartDocSnapshot) => {
            if (!cartDocSnapshot.exists()) {
                callback({
                    success: true,
                    cart: {}, // Reset to default values
                    foodLines: [],
                });
                return;
            }

            // Add query to order by 'createdAt' descending
            const foodLinesQuery = query(foodLinesCollectionRef, orderBy('createdAt', 'desc'));

            // Listen to the food line items
            const foodLinesUnsubscribe = onSnapshot(foodLinesQuery, (foodLineSnapshot) => {
                const foodLines = foodLineSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                // Trigger callback with updated data
                const timestampUpdatedFoodLines = transformTimestamps(foodLines);
                const timestampUpdatedCart = transformTimestamps(cartDocSnapshot.data());
                callback({
                    success: true,
                    cart: { id: cartDocSnapshot.id, ...timestampUpdatedCart },
                    foodLines: timestampUpdatedFoodLines,
                });
            });

            // Return a cleanup function to stop listening to changes
            return () => foodLinesUnsubscribe();
        });

        return cartUnsubscribe;
    } catch (error) {
        console.error('Error setting up live listeners for cart and food lines:', error);
        callback({ success: false, error: error.message || error });
    }
};
/**
 * Fetches a specific food line item in the cart for a customer from Firestore.
 *
 * @param {string} customerId - The ID of the customer.
 * @param {string} foodLineId - The ID of the food line item.
 * @returns {Object} - An object containing the food line item data or an error message.
 */
export const getFoodLine = async (foodLineId, authData) => {
    try {
        const customerId = authData?.uid; // Replace with the actual customer ID
        const foodLineRef = doc(
            firestore,
            `${collections.customers}/${customerId}/${collections.carts}/${customerId}/${subCollections.foodLines}/${foodLineId}`
        );

        // Fetch the food line document
        const foodLineSnapshot = await getDoc(foodLineRef);

        if (!foodLineSnapshot.exists()) {
            return { success: false, message: 'Food line item not found.' };
        }

        return {
            success: true,
            foodLine: { id: foodLineSnapshot.id, ...foodLineSnapshot.data() },
        };
    } catch (error) {
        console.error('Error fetching food line item:', error);
        return { success: false, error: error.message || error };
    }
};

const deleteQueue = [];

// Function to process the queue
const processDeleteQueue = async () => {
    if (deleteQueue.length === 0) return; // No operations in the queue

    const { foodLineId, authData, resolve, reject } = deleteQueue[0]; // Get the first item in the queue

    try {
        const customerId = authData?.uid;
        const foodLineRef = doc(
            firestore,
            `${collections.customers}/${customerId}/${collections.carts}/${customerId}/${subCollections.foodLines}/${foodLineId}`
        );
        const cartRef = doc(firestore, `${collections.customers}/${customerId}/${collections.carts}/${customerId}`);

        await runTransaction(firestore, async (transaction) => {
            // Get the food line data
            const foodLineSnapshot = await transaction.get(foodLineRef);
            if (!foodLineSnapshot.exists()) throw new Error('Food line not found');
            const foodLineData = foodLineSnapshot.data();
            const { unitPrice, quantity } = foodLineData;

            // Get the cart data
            const cartSnapshot = await transaction.get(cartRef);
            if (!cartSnapshot.exists()) throw new Error('Cart not found');
            const cartData = cartSnapshot.data();

            // Update cart values
            const updatedCount = cartData.count - quantity;
            const updatedItemTotal = cartData.itemTotal - unitPrice * quantity;

            // Delete the food line
            transaction.delete(foodLineRef);

            // Update the cart document
            transaction.update(cartRef, {
                count: updatedCount,
                itemTotal: updatedItemTotal,
            });

            // Remove coupon if the cart becomes empty
            if (updatedCount === 0) {
                transaction.delete(cartRef);
                localStorage.removeItem(localStorageKeys.COUPON);
                localStorage.removeItem(localStorageKeys.BITE_COIN_STATUS);
            }
        });

        resolve(true); // Resolve the promise for this call
    } catch (error) {
        console.error('Error deleting food line and updating cart:', error);
        reject(error); // Reject the promise for this call
    } finally {
        deleteQueue.shift(); // Remove the processed item from the queue
        processDeleteQueue(); // Process the next item in the queue
    }
};

// Public function to enqueue delete requests
export const handleDeleteFoodLine = async (foodLineId, authData) => {
    return new Promise((resolve, reject) => {
        deleteQueue.push({ foodLineId, authData, resolve, reject });
        if (deleteQueue.length === 1) {
            processDeleteQueue(); // Start processing if this is the only item in the queue
        }
    });
};

export const updateCartQuantity = async (productId, newQuantity, account, operation, customerId) => {
    const foodLineRef = doc(
        firestore,
        `${collections.customers}/${customerId}/${collections.carts}/${customerId}/${subCollections.foodLines}/${productId}`
    );
    const cartRef = doc(firestore, `${collections.customers}/${customerId}/${collections.carts}/${customerId}`);
    try {
        await runTransaction(firestore, async (transaction) => {
            // Get food line data
            const foodLineSnapshot = await transaction.get(foodLineRef);
            if (!foodLineSnapshot.exists()) throw new Error('Food line not found');
            const foodLineData = foodLineSnapshot.data();
            const unitPrice = foodLineData.unitPrice;

            // Calculate the updated amount and tax based on the new quantity
            const updatedAmount = unitPrice * newQuantity;

            // Get current cart data
            const cartSnapshot = await transaction.get(cartRef);
            if (!cartSnapshot.exists()) throw new Error('Cart not found');
            const cartData = cartSnapshot.data();

            let updatedCount, foodLineOldQuantity, foodLineOldAmount, updatedSubtotal;
            updatedCount = cartData.count - foodLineData.quantity + newQuantity;
            // Calculate updated count and subtotal for the cart
            if (operation === Operation.increment) {
                foodLineOldQuantity = newQuantity - 1;
                foodLineOldAmount = foodLineData.unitPrice * foodLineOldQuantity;
                updatedSubtotal = cartData.itemTotal - foodLineOldAmount + updatedAmount;
            } else {
                foodLineOldQuantity = newQuantity + 1;
                foodLineOldAmount = foodLineData.unitPrice * foodLineOldQuantity;
                updatedSubtotal = cartData.itemTotal - foodLineOldAmount + updatedAmount;
            }

            // Update the food line document
            if (newQuantity == 0) {
                transaction.delete(foodLineRef);
            } else {
                transaction.update(foodLineRef, {
                    quantity: newQuantity,
                    amount: updatedAmount,
                });
            }

            // Update the cart document
            transaction.update(cartRef, {
                count: updatedCount,
                itemTotal: updatedSubtotal,
            });
        });

        return { success: true };
    } catch (error) {
        console.error('Error updating cart quantity:', error);
        return { success: false, error: error.message || error };
    }
};

export const totalCalculationAndCartUpdate = async (customerId, billingInfo) => {
    const {
        tax,
        gst,
        cgst,
        sgst,
        vat,
        deliveryFee,
        discountAmount,
        usedCoins,
        earnedCoins,
        couponData,
        subTotal,
        total,
    } = billingInfo;
    const cartRef = doc(firestore, `${collections.customers}/${customerId}/${collections.carts}/${customerId}`);
    try {
        await runTransaction(firestore, async (transaction) => {
            // Get current cart data
            transaction.update(cartRef, {
                tax: tax,
                gst: gst,
                cgst: cgst,
                sgst: sgst,
                vat: vat,
                deliveryFee: deliveryFee,
                couponDiscount: discountAmount,
                biteCoinDiscount: usedCoins.value,
                earnedCoins: earnedCoins,
                couponData: couponData,
                usedCoins: usedCoins,
                subtotal: subTotal,
                total: total,
            });
        });
    } catch (error) {
        console.error('Error updating cart quantity:', error);
        return { success: false, error: error.message || error };
    }
};

/**
 * Removes a cart and its associated foodLines sub-collection.
 * @param {string} customerId - The ID of the customer whose cart is to be removed.
 */
export const removeCartTransaction = async (customerId, handleRefresh) => {
    const cartDocPath = `${collections.customers}/${customerId}/${collections.carts}/${customerId}`;
    const cartDocRef = doc(firestore, cartDocPath);
    const foodLinesPath = `${cartDocPath}/${subCollections.foodLines}`;
    const foodLinesRef = collection(firestore, foodLinesPath);

    try {
        await runTransaction(firestore, async (transaction) => {
            // Ensure cart exists
            const cartDoc = await transaction.get(cartDocRef);
            if (!cartDoc.exists()) {
                throw new Error('Cart does not exist.');
            }

            // Remove foodLines sub-collection documents
            const foodLinesSnapshot = await getDocs(foodLinesRef);
            foodLinesSnapshot.forEach((foodLineDoc) => {
                transaction.delete(foodLineDoc.ref);
            });
            handleRefresh();
            transaction.delete(cartDocRef);
        });

        return true;
    } catch (error) {
        return false;
    }
};

/**
 * Updates only the address object in the customer's cart.
 * @param {string} customerId - The ID of the customer whose cart is to be updated.
 * @param {Object} newAddress - The new address data to update (excluding name and phone).
 */
export const updateCartAddress = async (customerId, newAddress, type, storeData = null) => {
    const cartDocPath = `${collections.customers}/${customerId}/${collections.carts}/${customerId}`;
    const cartDocRef = doc(firestore, cartDocPath);

    try {
        await runTransaction(firestore, async (transaction) => {
            // Fetch the current cart data
            const cartDoc = await transaction.get(cartDocRef);

            if (!cartDoc.exists()) {
                throw new Error('Cart does not exist.');
            }

            // Extract existing customer data
            const cartData = cartDoc.data();
            const currentCustomer = cartData.customer || {};

            // Prepare the updated address object
            const updatedAddress = {
                ...currentCustomer.address,
                ...newAddress, // Merge new address details
            };

            // Construct the update object
            const updateData = {
                'customer.address': updatedAddress,
                service: type,
            };
            // Add the store property if the type is TAKEAWAY and storeData is provided
            if (type === ORDER_TYPES.TAKEAWAY && storeData) {
                updateData.store = {
                    name: storeData?.store?.name,
                    address: storeData?.store?.address,
                    outletCode: storeData?.store?.outletCode,
                    companyId: storeData?.store?.companyId,
                    storeId: storeData?.store?.id,
                    location: {
                        lat: storeData?.store?.location?.coordinates[1],
                        lng: storeData?.store?.location?.coordinates[0],
                    },
                    open: storeData?.store?.open,
                    close: storeData?.store?.close,
                    scheduleTime: storeData?.scheduledTime || '',
                };
                updateData.outletCode = storeData?.store?.outletCode;
            }
            // Update the cart with the new address
            transaction.update(cartDocRef, updateData);
        });

        return true;
    } catch (error) {
        return false;
    }
};

export const updateCartField = async (customerId, updateFields) => {
    const cartDocPath = `${collections.customers}/${customerId}/${collections.carts}/${customerId}`;
    const cartDocRef = doc(firestore, cartDocPath);

    try {
        await runTransaction(firestore, async (transaction) => {
            // Update the cart with the provided fields and values
            transaction.update(cartDocRef, updateFields);
        });

        return true;
    } catch (error) {
        return false;
    }
};
