import { viewDateFormat } from '@/lib/helpers/common';
import EditProfileForm from './editProfileForm';
import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl'; // Assuming you use `next-intl` for translations

const ProfileEdit = ({
  profileData,
  setEditEnabled,
  editEnabled
}) => {
  const {
    dob,
    gender,
    email
  } = profileData || {};
  const t = useTranslations('profile'); // Fetching translations for the component

  return <>
            <div className="edit_Header">
                <h4>{t('basicInformation')}</h4>
            </div>
            {editEnabled ? <EditProfileForm editEnabled={editEnabled} setEditEnabled={setEditEnabled} /> : <div className="settingsSection disabledField">
                    <div className="rowGroup">
                        <div className="formGroup">
                            <label htmlFor="dob">{t('dateOfBirth')}</label>
                            <input type="text" className="formControl" value={viewDateFormat(dob) || '-'} disabled />
                        </div>
                        <div className="formGroup">
                            <label htmlFor="gender">{t('gender')}</label>
                            <input type="text" className="formControl" value={gender ? t(gender?.toLowerCase()) : '-'} disabled />
                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="email">{t('email')}</label>
                        <input type="text" className="formControl" value={email || '-'} disabled />
                    </div>
                </div>}
        </>;
};
ProfileEdit.propTypes = {
  profileData: PropTypes.object,
  setEditEnabled: PropTypes.func,
  editEnabled: PropTypes.bool
};
export default ProfileEdit;