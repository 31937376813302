import { ORDER_TYPES } from '@/configs/constants';
import useConfirmationModal from '@/hooks/useConfirmationModal';
import { addSubCollection, deleteDocument, executeQuery, getCollectionRef, getDocRef, updateDocument } from '@/lib/firebase/query';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import { validateField } from '@/utils/validations';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../Button';
import ChevronRight from '../icons/chevronRight';
import CloseIcon from '../icons/CloseIcon';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';
import AddressForm, { addressTypes } from '../LocationSelector/AddressForm';
import Delivery from '../LocationSelector/components/Delivery';
import { LOCATION_MODES, MODAL_MODES } from '../LocationSelector/config';
import { parseAddressResponse } from '../LocationSelector/DataFormatter';
import useLocationForm from '../LocationSelector/hooks/useLocationForm';
import LocationMap from '../LocationSelector/LocationMap';
const AddressBooks = ({
  onClose
}) => {
  const router = useRouter();
  const [addresses, setAddresses] = useState([]);
  const [confirmLocation, setConfirmLocation] = useState(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [editingAddress, setEditingAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newAddress, setNewAddress] = useState({});
  const [addNewAddress, setAddNewAddress] = useState(null);
  const [errors, setErrors] = useState({});
  const [defaultLocation, setDefaultLocation] = useState(null);
  const t = useTranslations('profile');
  const {
    mode = MODAL_MODES.searchLocation
  } = router.query || {};
  const authData = useSelector(state => state.auth)?.auth;
  const {
    confirmAction,
    confirmationModal
  } = useConfirmationModal();
  const isAddressEmpty = obj => Object.keys(obj).length === 0;
  const {
    formData,
    handleSearchComplete,
    handleSuggestionSelect
  } = useLocationForm();
  const type = ORDER_TYPES.DELIVERY;
  const config = useMemo(() => {
    const baseConfig = LOCATION_MODES[mode]?.[type] || LOCATION_MODES.searchLocation[type];
    return baseConfig;
  }, [mode, type]);
  const getSavedLocations = useCallback(async () => {
    setIsLoading(true);
    try {
      const parentDocRef = getDocRef(collections.customers, authData.uid);
      const subCollectionRef = getCollectionRef(subCollections.addresses, parentDocRef);
      const response = await executeQuery({
        ref: subCollectionRef,
        sort: {
          field: 'createdAt',
          order: 'desc'
        },
        conditions: [{
          field: 'isSelected',
          operator: '==',
          value: true
        }],
        pageSize: 20
      });
      setAddresses(response);
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    getSavedLocations();
  }, [getSavedLocations]);
  useEffect(() => {
    const handleClickOutside = event => {
      if (!event.target.closest('.dropdown-btn')) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);
  useEffect(() => {
    setNewAddress(editingAddress?.address || {});
  }, [editingAddress]);
  const handleChange = (name, value) => {
    setNewAddress({
      ...newAddress,
      [name]: value
    });
    const isValid = validateData({
      address: {
        ...newAddress,
        [name]: value
      }
    });
    if (isValid.errors && addNewAddress) {
      setErrors(prev => {
        const newErrors = {
          ...prev
        };
        ['directions', 'houseNumber', 'name', 'phone'].forEach(key => {
          delete newErrors[key];
        });
        return newErrors;
      });
    }
    if (isValid.errors) {
      setErrors(isValid.errors);
    }
  };
  const validateData = formattedData => {
    const {
      address
    } = formattedData;
    const errors = {};
    const baseFields = ['directions', 'houseNumber', 'name', 'phone'];
    const fieldsToValidate = !address.addressType ? [...baseFields, 'addressType'] : baseFields;
    fieldsToValidate.forEach(field => {
      const fieldStatus = validateField({
        name: field,
        value: address[field],
        errors,
        formData: formattedData
      });
      if (!fieldStatus.isValid) {
        errors[field] = fieldStatus.errors[field];
      }
    });
    return {
      errors,
      status: Object.values(errors).filter(i => i).length === 0
    };
  };
  const handleDelete = useCallback(async addressId => {
    try {
      const confirmModalConfig = {
        title: t('deleteAddress'),
        message: t('deleteAddressDescription'),
        confirmText: t('yesLablel'),
        cancelText: t('noLabel')
      };
      const userConfirmation = await confirmAction(confirmModalConfig);
      if (userConfirmation) {
        setIsLoading(true);
        const path = `${collections.customers}/${authData.uid}/${subCollections.addresses}`;
        await deleteDocument(path, addressId);
        getSavedLocations();
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
      setOpenDropdownId(null);
    }
  }, []);
  const prepareAddressData = data => {
    return {
      address: {
        name: data.name,
        phone: data.phone,
        houseNumber: data.houseNumber,
        apartment: data.apartment || '',
        directions: data.directions,
        addressType: data.addressType,
        geoData: {
          formattedAddress: data?.geoData?.formattedAddress || '',
          coordinates: {
            ...data?.geoData?.coordinates
          }
        }
      },
      createdAt: new Date().toISOString(),
      sOrder: new Date().toISOString()
    };
  };
  const handleUpdate = useCallback(async () => {
    setIsLoading(true);
    try {
      const updatedData = prepareAddressData(newAddress);
      const path = `${collections.customers}/${authData.uid}/${subCollections.addresses}`;
      await updateDocument(path, editingAddress.id, updatedData);
      setEditingAddress(null);
      getSavedLocations();
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  }, [editingAddress, newAddress]);
  const handleAddAddress = useCallback(async () => {
    setIsLoading(true);
    const isValid = validateData({
      address: {
        ...newAddress
      }
    });
    setErrors(isValid.errors);
    if (Object.keys(isValid.errors).length > 0) {
      setIsLoading(false);
      return;
    }
    try {
      const updatedData = prepareAddressData(newAddress);
      await addSubCollection({
        collectionName: collections.customers,
        documentId: authData.uid,
        subCollectionName: subCollections.addresses,
        subCollectionData: updatedData
      });
      setAddNewAddress(null);
      getSavedLocations();
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  }, [addNewAddress, newAddress]);
  const toggleDropdown = useCallback(addressId => {
    setOpenDropdownId(prev => prev === addressId ? null : addressId);
  }, []);
  const handleEditClick = useCallback(address => {
    setEditingAddress(address);
    setOpenDropdownId(null);
  }, []);
  const handleAddAddressClick = useCallback(() => {
    setAddNewAddress(true);
    setOpenDropdownId(null);
  }, []);
  const formatAddress = useCallback(address => {
    const parts = [];
    if (address.houseNumber) parts.push(address.houseNumber);
    if (address.apartment) parts.push(address.apartment);
    if (address.geoData?.formattedAddress && typeof address.geoData.formattedAddress === 'string') {
      parts.push(address.geoData.formattedAddress);
    } else {
      parts.push(address.geoData.formattedAddress?.place?.formattedAddress);
    }
    if (address.directions) parts.push(address.directions);
    return parts.join(', ');
  }, []);
  const renderAddressItem = useCallback(address => <div key={address.id} className="item-locations" style={{
    cursor: 'pointer'
  }}>
                <div className="item-header">
                    <div className="dropdown-block">
                        <span>
                            {addressTypes.includes(address.address.addressType) ? t(address.address.addressType) : address.address.addressType}
                        </span>
                        <div className="dropdown-btn">
                            <button className="menu-dot-icon" onClick={e => {
            e.stopPropagation();
            toggleDropdown(address.id);
          }}>
                                <ThreeDotsIcon />
                            </button>
                            {openDropdownId === address.id && <div className="dropdown">
                                    <ul>
                                        <li>
                                            <button onClick={e => {
                  e.preventDefault();
                  handleEditClick(address);
                }}>
                                                {t('edit')}
                                            </button>
                                        </li>
                                        <li className="delete-btn">
                                            <button onClick={e => {
                  e.stopPropagation();
                  handleDelete(address.id);
                }}>
                                                {t('delete')}
                                            </button>
                                        </li>
                                    </ul>
                                </div>}
                        </div>
                    </div>
                    <h6>{address.address.houseNumber}</h6>
                </div>
                <p>{formatAddress(address.address)}</p>
            </div>, [openDropdownId, toggleDropdown, handleEditClick, handleDelete, formatAddress]);
  const getCoordinates = useCallback((addressCoordinates, defaultLoc) => {
    if (defaultLoc) {
      return {
        lat: defaultLoc.data.latitude,
        lng: defaultLoc.data.longitude
      };
    } else if (addressCoordinates && Object.keys(addressCoordinates).length > 0) {
      return addressCoordinates;
    } else {
      return null;
    }
  }, []);
  const updateLocationData = (coordinates, formattedAddress = '') => {
    setDefaultLocation({
      data: {
        latitude: coordinates.lat,
        longitude: coordinates.lng
      }
    });
    handleChange('geoData', {
      formattedAddress,
      coordinates: {
        lat: coordinates.lat,
        lng: coordinates.lng
      }
    });
    setErrors({});
  };
  const handlePlaceSelect = selectedPlace => {
    setConfirmLocation(true);
    const formattedAddress = parseAddressResponse(selectedPlace);
    updateLocationData(formattedAddress?.address?.geoData?.coordinates, formattedAddress);
  };
  const confirmCurrentLocation = locationCoordinates => {
    setConfirmLocation(true);
    updateLocationData(locationCoordinates, '');
  };
  if (confirmationModal) {
    return confirmationModal;
  }
  if (editingAddress || addNewAddress) {
    return <>
                <button className="overlay" onClick={onClose}></button>
                <section className="drawer address-book">
                    <div className="sheetHeader">
                        <h4>
                            {editingAddress ? `${t('address_edit_title')}` : `${t('address_create_title')}`}{' '}
                            <small>
                                {' '}
                                {editingAddress ? `${t('update')}` : `${t('add')}`} {t('address_description')}
                            </small>
                        </h4>
                        <button className="closeBtn" onClick={() => {
            setConfirmLocation(null);
            setDefaultLocation(null);
            if (editingAddress) {
              setEditingAddress(null);
            } else {
              setAddNewAddress(null);
              setNewAddress({});
              setErrors({});
            }
          }}>
                            {t('back')}
                        </button>
                    </div>
                    <div className="location-wrap">
                        <Delivery formData={formData} handleChange={() => {}} errors={errors} config={config} onClose={() => {}} handleSearchComplete={handleSearchComplete} handleSuggestionSelect={handleSuggestionSelect} goToNextStep={() => {}} mode={mode} handleBackClick={() => {}} validateField={validateField} handlePlaceSelect={handlePlaceSelect} updateCoordinates={confirmCurrentLocation} handleAddNewAddress={() => {}} currentStep={() => {}} shouldSearch={true} setShouldSearch={() => {}} />
                        {(confirmLocation && !editingAddress || editingAddress) && <>
                                <LocationMap onLocationChange={coordinates => {
              setDefaultLocation({
                data: {
                  latitude: coordinates?.lat,
                  longitude: coordinates?.lng
                }
              });
              handleChange('geoData', {
                formattedAddress: '',
                coordinates: coordinates
              });
            }} coordinates={getCoordinates(editingAddress?.address?.geoData?.coordinates, defaultLocation)} preventZoom={mode === MODAL_MODES.globalOrder} preventMarkerMovement={mode === MODAL_MODES.globalOrder} />
                                <AddressForm currentAddress={newAddress} handleFieldChange={handleChange} validateField={validateField} errorsData={errors} isNewAddress={addNewAddress} />
                                <Button className="submint-btn" onClick={addNewAddress ? handleAddAddress : handleUpdate} isLoading={isLoading} disabled={editingAddress && (isAddressEmpty(newAddress) || Object.values(errors).filter(i => i).length > 0)}>
                                    {t('saveAddress')}
                                </Button>
                            </>}
                    </div>
                </section>
            </>;
  }
  return <>
            <button className="overlay" onClick={onClose}></button>
            <section className="drawer address-book">
                <div className="sheetHeader">
                    <div className="back-icon">
                        <button className="back-btn" onClick={onClose}>
                            <ChevronRight data-sentry-element="ChevronRight" data-sentry-source-file="index.jsx" />
                        </button>

                        <h4>{t('address_book')}</h4>
                    </div>

                    <button className="closeBtn" onClick={onClose}>
                        <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="index.jsx" />
                    </button>
                </div>
                <div className="location-wrap">
                    <div className="saved-locations">
                        <h4>{t('saved_address')}</h4>
                        <AddressListStatus isLoading={isLoading} addresses={addresses} renderAddressItem={renderAddressItem} data-sentry-element="AddressListStatus" data-sentry-source-file="index.jsx" />
                    </div>
                </div>
                <button className="logoutProfile" onClick={handleAddAddressClick}>
                    {t('add_new_address')}
                </button>
            </section>
        </>;
};
export default AddressBooks;
AddressBooks.propTypes = {
  onClose: PropTypes.func
};
const AddressListStatus = ({
  isLoading,
  addresses,
  renderAddressItem
}) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (addresses.length === 0) {
    return <div>No saved addresses found.</div>;
  }
  return addresses.map(renderAddressItem);
};
AddressListStatus.propTypes = {
  isLoading: PropTypes.bool,
  addresses: PropTypes.array,
  renderAddressItem: PropTypes.func
};