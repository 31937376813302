import AddressBooks from '@/components/AddressBook';
import LocaleSwitch, { localeSwitchType } from '@/components/header/LocaleSwitch';
import AddressBook from '@/components/icons/AddressBook';
import ChangeLanguage from '@/components/icons/ChangeLanguage';
import ChevronRight from '@/components/icons/chevronRight';
import ChooseCountry from '@/components/icons/ChooseCountry';
import CoinIcon from '@/components/icons/CoinIcon';
import Favourite from '@/components/icons/Favourite';
import LockIcon from '@/components/icons/LockIcon';
import LogoutIcon from '@/components/icons/LogoutIcon';
import Orders from '@/components/icons/Orders';
import PushNotification from '@/components/icons/PushNotification';
import TermsConditions from '@/components/icons/TermsConditions';
import Loader from '@/components/loader';
import { CommonModal } from '@/components/Modal/CommonModal';
import { Toggle } from '@/components/Toggle/Toggle';
import { localStorageKeys, modalTypeKey } from '@/configs/constants';
import { useAuth } from '@/hooks/useAuth';
import useConfirmationModal from '@/hooks/useConfirmationModal';
import useLocalStorage from '@/hooks/useLocalStorage';
import useUrlParams from '@/hooks/useUrlParams';
import { capitalizeFirstLetter, getUserFirstAndLastLetter } from '@/lib/helpers/common';
import { resetStore, useAppStore, wrapper } from '@/redux/store';
import { deleteUser, updateUser } from '@/service/user.service';
import loadMessages from '@/utils';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MyFavourite from './myFavourite';
import Myorders from './myOrders';
import Policy from './Policy';
import ProfileEdit from './profileEdit';
import ProfileHeader from './profileHeader';
const screens = {
  PROFILE_HOME: 'profileHome',
  ADDRESS_BOOK: 'addressBook',
  EDIT_ADDRESS: 'editAddress',
  POLICY: 'policy',
  FAVOURITES: 'favourites'
};
const Profile = ({
  setShowProfile,
  showProfile
}) => {
  const store = useAppStore();
  const route = useRouter();
  const {
    logout
  } = useAuth();
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const t = useTranslations('profile');
  const {
    addMultipleParams
  } = useUrlParams();
  const orderData = useSelector(store => store.order).order;
  const profileData = useSelector(store => store.auth)?.auth;
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [showMyOrders, setShowMyOrders] = useState(false);
  const [isPush, setIsPush] = useState(profileData?.isPush);
  const [currentScreen, setCurrentScreen] = useState(screens.PROFILE_HOME);
  const [loading, setLoading] = useState(false);
  const {
    firstName,
    lastName,
    phoneNumber,
    phoneCode,
    loyalty
  } = profileData || {};
  const loyaltyPoint = loyalty?.[orderData?.companyData?.country?.countryCode.toLowerCase()] || 0;
  const {
    confirmAction,
    confirmationModal
  } = useConfirmationModal();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  const updateScreen = screen => {
    setCurrentScreen(screen);
  };
  if (showMyOrders) {
    return <Myorders setShowProfile={setShowProfile} setShowMyOrders={setShowMyOrders} />;
  }
  if (currentScreen === screens.ADDRESS_BOOK) {
    return <AddressBooks setCurrentScreen={setCurrentScreen} onClose={() => updateScreen(screens.PROFILE_HOME)} screens={screens} t={t} />;
  }
  if (currentScreen === screens.POLICY) {
    return <Policy setShowProfile={setShowProfile} setCurrentScreen={setCurrentScreen} onClose={() => updateScreen(screens.PROFILE_HOME)} screens={screens} t={t} />;
  }
  if (currentScreen === screens.FAVOURITES) {
    return <MyFavourite setShowProfile={setShowProfile} setShowFavouriteCard={() => updateScreen(screens.PROFILE_HOME)} showProfile={showProfile} />;
  }
  const handleToggled = () => {
    setIsPush(!isPush);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsPush(profileData?.isPush);
    setOpenModal(false);
  };
  const handleCloseAccountDeleteModal = () => {
    setOpenDeleteAccountModal(false);
  };
  const handleDeleteUserModal = async () => {
    setLoading(true);
    await deleteUser({
      uid: profileData?.uid
    });
    setLoading(false);
    handleLogout();
  };
  const handlePushSubmit = async selected => {
    setIsPush(selected);
    await updateUser({
      isPush: selected
    }).then(res => res.code === 200 && toast.success(t('push_notification')));
    setOpenModal(false);
  };
  const handleLogout = async () => {
    try {
      await logout();
      actions.clearAllExcept([`${localStorageKeys.ACCOUNT_DATA}.${localStorageKeys.DEVICE_LOCATION}`, `${localStorageKeys.ACCOUNT_DATA}.${localStorageKeys.SELECTED_COUNTRY}`, `${localStorageKeys.ACCOUNT_DATA}.${localStorageKeys.SELECTED_ADDRESS}`]);
      route.push('/auth/signin');
      setShowProfile(false);
      resetStore(store);
    } catch (error) {
      console.error(error);
    }
  };
  const chooseCountry = e => {
    e.preventDefault();
    addMultipleParams({
      [modalTypeKey]: 'selectCountry'
    }, {
      shallow: false
    });
  };
  const menuItems = {
    orders: [{
      id: 'myOrders',
      icon: <Orders />,
      label: 'orders_myOrders',
      action: () => setShowMyOrders(!showMyOrders)
    }, {
      id: 'favouriteFood',
      icon: <Favourite />,
      label: 'orders_favouriteFood',
      action: () => setCurrentScreen(screens.FAVOURITES)
    }, {
      id: 'addressBook',
      icon: <AddressBook />,
      label: 'orders_addressBook',
      action: () => setCurrentScreen(screens.ADDRESS_BOOK)
    }, {
      id: 'chooseCountry',
      icon: <ChooseCountry />,
      label: 'orders_chooseCountry',
      action: chooseCountry
    }],
    settings: [{
      id: 'changeLanguage',
      icon: <ChangeLanguage />,
      label: 'settings_changeLanguage',
      action: () => {},
      // Define the action if needed
      component: <LocaleSwitch type={localeSwitchType.SELECT} />
    }, {
      id: 'pushNotification',
      icon: <PushNotification />,
      label: 'settings_pushNotification',
      action: () => handleToggled(),
      component: <Toggle selected={isPush} toggleSelected={handleToggled} />
    }, {
      id: 'policy',
      icon: <LockIcon />,
      label: 'policy',
      action: () => setCurrentScreen(screens.POLICY)
    }, {
      id: 'aboutUs',
      icon: <TermsConditions />,
      label: 'aboutUs',
      link: {
        url: '/footerItems/aboutUs',
        newTab: true
      }
    }, {
      id: 'deleteAccount',
      icon: null,
      label: 'settings_deleteAccount',
      action: () => {
        setOpenDeleteAccountModal(true);
      },
      // Define delete account action
      component: <></>
    }]
  };
  if (confirmationModal) {
    return confirmationModal;
  }
  const handleLogutBtn = async () => {
    const confirmModalConfig = {
      title: t('logout'),
      message: t('logoutModalContent'),
      confirmText: t('logout'),
      cancelText: t('Cancel')
    };
    const userConfirmation = await confirmAction(confirmModalConfig);
    if (userConfirmation) {
      setLoading(false);
      handleLogout();
    }
  };
  return <>
            {loading && <div className="min-h-screen flex items-center justify-center">
                    <Loader />
                </div>}
            <div className="overlay"></div>
            <section className="drawer">
                <ProfileHeader setShowProfile={setShowProfile} showProfile={showProfile} title={t('profile_title')} data-sentry-element="ProfileHeader" data-sentry-source-file="index.jsx" />
                <div>
                    <div className="profileDetails-wrap">
                        {!editEnabled && <div className="profileDetails">
                                <div className="profileDetails-header">
                                    <div className="avatar">
                                        <div className="avatar_icon">
                                            <span>{getUserFirstAndLastLetter(firstName, lastName)}</span>
                                        </div>
                                    </div>
                                    <h4>
                                        {capitalizeFirstLetter(firstName)} {capitalizeFirstLetter(lastName)}
                                        <small>
                                            +{phoneCode}-{phoneNumber}
                                        </small>
                                    </h4>
                                </div>
                                <div>
                                    <button className="editBtn" onClick={() => setEditEnabled(prev => !prev)}>
                                        {editEnabled ? '' : t('edit')}
                                    </button>
                                </div>
                            </div>}

                        <ProfileEdit profileData={profileData} setEditEnabled={setEditEnabled} editEnabled={editEnabled} data-sentry-element="ProfileEdit" data-sentry-source-file="index.jsx" />
                    </div>
                    <button className="bite-btn profile-bite-btn">
                        <CoinIcon data-sentry-element="CoinIcon" data-sentry-source-file="index.jsx" />
                        <div>
                            {loyaltyPoint === 0 ? t('zeroBiteCoin') : <>
                                    {t('you_have')}{' '}
                                    <span>
                                        {loyaltyPoint} {t('bitecoins')}.
                                    </span>
                                </>}
                        </div>
                    </button>
                </div>

                {/* orders section */}
                <div className="edit_Header">
                    <h4>{t('orders_title')}</h4>
                </div>
                <div className="settingsSection ">
                    <ul>
                        {menuItems.orders.map(item => <li key={item.id}>
                                <Link href="" onClick={item.action}>
                                    <div>
                                        <span className="icon">{item.icon}</span>
                                        <span className="menuText">{t(item.label)}</span>
                                    </div>
                                    <span className="icon chevron">
                                        <ChevronRight />
                                    </span>
                                </Link>
                            </li>)}
                    </ul>
                </div>

                {/* settings section */}
                <div className="edit_Header">
                    <h4>{t('settings_title')}</h4>
                </div>
                <div className="settingsSection">
                    <ul>
                        {menuItems.settings.map(item => <li key={item.id}>
                                <Link href={item.link?.url || ''} target={item.link?.newTab ? '_blank' : '_self'} onClick={item.action}>
                                    <div>
                                        <span className="icon">{item.icon}</span>
                                        <span className={`menuText ${item.id === 'deleteAccount' ? 'deleteAccount deleteAccountRed' : ''}`}>
                                            {t(item.label)}
                                        </span>
                                    </div>
                                    {item.component ? item.component : <span className="icon chevron">
                                            <ChevronRight />
                                        </span>}
                                </Link>
                            </li>)}
                    </ul>
                </div>
                <button className="logoutProfile" onClick={handleLogutBtn}>
                    <LogoutIcon data-sentry-element="LogoutIcon" data-sentry-source-file="index.jsx" />
                    {t('logout')}
                </button>
            </section>
            <CommonModal title={t('push_notification_modal_title')} show={openModal} onCancel={handleCloseModal} onConfirm={() => handlePushSubmit(isPush)} confirmText={t('Confirm')} cancelText={t('Cancel')} isOpen={openModal} closeIcon={true} data-sentry-element="CommonModal" data-sentry-source-file="index.jsx" />
            <CommonModal title={t('settings_deleteAccount')} message={t('deleteProfilePopUp')} show={openDeleteAccountModal} onCancel={handleCloseAccountDeleteModal} onConfirm={handleDeleteUserModal} confirmText={t('deleteAccount')} cancelText={t('Cancel')} isOpen={openDeleteAccountModal} closeIcon={true} isLoading={loading} isButtonLoader={true} data-sentry-element="CommonModal" data-sentry-source-file="index.jsx" />
        </>;
};
export const getStaticProps = wrapper.getStaticProps(() => async ({
  locale
}) => {
  return {
    props: {
      locale,
      messages: await loadMessages(locale)
    }
  };
});
Profile.propTypes = {
  setShowProfile: PropTypes.func.isRequired,
  showProfile: PropTypes.bool.isRequired
};
export default Profile;