import { firestore } from '@/configs/firebase.config';
import { transformTimestamps } from '@/utils';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore';

/**
 * Adds a document to the specified collection.
 *
 * @param {string} collectionName Name of the collection to add the document to.
 * @param {object} documentData The data to add to the document.
 * @returns {Promise<string>} The ID of the newly added document.
 * @throws {Error} If there is an error adding the document.
 */
export async function addDocument(collectionName, documentData) {
    try {
        const docRef = await addDoc(collection(firestore, collectionName), documentData);
        return docRef.id;
    } catch (e) {
        console.error('Error adding document:', e);
        throw e;
    }
}

/**
 * Sets a document in the specified collection.
 *
 * @param {string} collectionName Name of the collection to set the document in.
 * @param {object} documentData The data to set in the document.
 * @returns {Promise<boolean>} True if the document is set successfully, false otherwise.
 * @throws {Error} If there is an error setting the document.
 */
export async function setDocument(collectionName, documentData) {
    try {
        const docRef = doc(firestore, collectionName, documentData.id);
        await setDoc(docRef, documentData);
        return true;
    } catch (error) {
        console.error('error: ', error);
        throw error;
    }
}

/**
 * Retrieves a document from the specified collection.
 *
 * @param {string} collectionName Name of the collection to retrieve the document from.
 * @param {string} documentId The ID of the document to retrieve.
 * @returns {Promise<object>} The data from the document, or null if the document does not exist.
 * @throws {Error} If there is an error retrieving the document.
 */
export async function getDocument(collectionName, documentId) {
    try {
        const docRef = doc(firestore, collectionName, documentId);
        const docSnap = await getDoc(docRef);
        return transformTimestamps(docSnap.data());
    } catch (e) {
        console.error('Error getting document:', e);
        throw e;
    }
}

/**
 * Updates a document in the specified collection with the given data.
 *
 * @param {string} collectionName Name of the collection to update the document in.
 * @param {string} documentId The ID of the document to update.
 * @param {object} updatedData The data to update the document with.
 * @returns {Promise<boolean>} True if the document is updated successfully, false otherwise.
 * @throws {Error} If there is an error updating the document.
 */
export async function updateDocument(collectionName, documentId, updatedData) {
    try {
        const documentRef = doc(firestore, collectionName, documentId);
        await updateDoc(documentRef, updatedData);
        return true;
    } catch (e) {
        console.error('Error updating document:', e);
        throw e;
    }
}

/**
 * Deletes a document from the specified collection.
 *
 * @param {string} collectionName Name of the collection to delete the document from.
 * @param {string} documentId The ID of the document to delete.
 * @returns {Promise<void>} Resolves if the document is deleted successfully, rejects otherwise.
 * @throws {Error} If there is an error deleting the document.
 */
export async function deleteDocument(collectionName, documentId) {
    try {
        const documentRef = doc(firestore, collectionName, documentId);
        return await deleteDoc(documentRef);
    } catch (e) {
        console.error('Error deleting document:', e);
        throw e;
    }
}

/**
 * Lists documents in the given collection, with optional filters.
 *
 * The filters object can contain the following properties:
 * - `limit`: The maximum number of documents to return.
 * - `sort`: An object with two properties:
 *   - `field`: The field to sort by.
 *   - `order`: The order to sort by, either "asc" or "desc".
 *
 * @param {string} collectionName The name of the collection to list documents from.
 * @param {object} [filters={}] The filters object.
 * @returns {Promise<Array<{id: string, [key: string]: any}>>} An array of documents, with their IDs and data.
 * @throws {Error} If there is an error listing the documents.
 */
export async function listDocuments(collectionName, filters = {}) {
    try {
        const collectionRef = collection(firestore, collectionName);

        const count = filters?.limit || null;
        const sort = filters?.sort || null;

        let dbQuery = query(collectionRef);

        if (sort?.field && (sort.order === 'asc' || sort.order === 'desc')) {
            dbQuery = query(collectionRef, orderBy(sort.field, sort.order));
        }

        if (count) {
            dbQuery = query(collectionRef, limit(count));
        }

        const querySnapshot = await getDocs(dbQuery);
        return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
        console.error('Error listing items:', e);
        throw e;
    }
}

/**
 * Returns the number of documents in the given collection.
 *
 * @param {string} collectionName The name of the collection to count documents from.
 * @returns {Promise<number>} The number of documents in the collection.
 * @throws {Error} If there is an error counting the documents.
 */
export async function countCollection(collectionName) {
    try {
        const collectionRef = collection(firestore, collectionName);
        const querySnapshot = await getCountFromServer(collectionRef);
        return querySnapshot.data().count;
    } catch (e) {
        console.error('Error counting items:', e);
        throw e;
    }
}

/**
 * Stores multiple documents in the specified collection.
 *
 * The documents will be stored atomically, meaning that either all documents will be stored or none of them will be
 * stored in the case of an error.
 *
 * @param {string} collectionName The name of the collection to store the documents in.
 * @param {object[]} documentsData An array of objects, where each object is the data to store in a document.
 * @returns {Promise<boolean>} True if the documents were stored successfully, false otherwise.
 * @throws {Error} If there is an error storing the documents.
 */
export async function bulkStoreDocuments(collectionName, documentsData) {
    try {
        const batch = writeBatch(firestore);
        documentsData.forEach((documentData) => {
            const docRef = doc(firestore, collectionName);
            batch.set(docRef, documentData);
        });
        await batch.commit();
        return true;
    } catch (error) {
        console.error('Error bulk storing documents:', error);
        throw error;
    }
}

export function getDocRef(collectionName, id, ref = firestore) {
    return doc(ref, collectionName, id);
}

export function getCollectionRef(collectionName, ref = firestore) {
    return collection(ref, collectionName);
}

export async function executeQuery({ ref, conditions, sort, pageSize }) {
    try {
        let _query = query(ref);

        if (conditions && Array.isArray(conditions)) {
            conditions.forEach((condition) => {
                const { field, operator, value } = condition;
                _query = query(_query, where(field, operator, value));
            });
        }

        if (pageSize) {
            _query = query(ref, limit(pageSize));
        }

        if (sort?.field && ['asc', 'desc'].includes(sort.order)) {
            _query = query(_query, orderBy(sort.field, sort.order));
        }
        const querySnapshot = await getDocs(_query);
        return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error('error: ', error);
        throw error;
    }
}
/**
 * Adds a document to a subcollection of a specified document.
 *
 * @param {string} collectionName The name of the parent collection.
 * @param {string} documentId The ID of the parent document.
 * @param {string} subCollectionName The name of the subcollection to add the document to.
 * @param {object} subCollectionData The data to add to the subcollection document.
 * @returns {Promise<string>} The ID of the newly added subcollection document.
 * @throws {Error} If there is an error adding the subcollection document.
 */
export async function addSubCollection({ collectionName, documentId, subCollectionName, subCollectionData }) {
    try {
        const parentDocRef = doc(firestore, collectionName, documentId);
        const subCollectionRef = collection(parentDocRef, subCollectionName);
        const subDocRef = doc(subCollectionRef);
        await setDoc(subDocRef, { ...subCollectionData, id: subDocRef.id });

        return subDocRef.id;
    } catch (error) {
        console.error('Error adding subcollection document:', error);
        throw error;
    }
}

/**
 * Retrieves all documents from a subcollection of a specified document.
 *
 * @param {string} collectionName The name of the parent collection.
 * @param {string} documentId The ID of the parent document.
 * @param {string} subCollectionName The name of the subcollection to retrieve documents from.
 * @returns {Promise<Array<object>>} An array of documents from the subcollection.
 * @throws {Error} If there is an error retrieving the subcollection documents.
 */
export async function getSubCollection({ collectionName, documentId, subCollectionName }) {
    try {
        const parentDocRef = doc(firestore, collectionName, documentId);
        const subCollectionRef = collection(parentDocRef, subCollectionName);
        const snapshot = await getDocs(subCollectionRef);

        const subCollectionDocs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        return subCollectionDocs;
    } catch (error) {
        console.error('Error retrieving subcollection documents:', error);
        throw error;
    }
}

/**
 * Updates a document in a subcollection of a specified document.
 *
 * @param {string} collectionName The name of the parent collection.
 * @param {string} documentId The ID of the parent document.
 * @param {string} subCollectionName The name of the subcollection to update the document in.
 * @param {string} subDocumentId The ID of the document to update in the subcollection.
 * @param {object} updatedData The data to update in the subcollection document.
 * @returns {Promise<boolean>} True if the document is updated successfully, false otherwise.
 * @throws {Error} If there is an error updating the subcollection document.
 */
export async function updateSubCollection({
    collectionName,
    documentId,
    subCollectionName,
    subDocumentId,
    updatedData,
}) {
    try {
        const parentDocRef = doc(firestore, collectionName, documentId);
        const subDocRef = doc(collection(parentDocRef, subCollectionName), subDocumentId);
        await updateDoc(subDocRef, updatedData);
        return true;
    } catch (error) {
        console.error('Error updating subcollection document:', error);
        throw error;
    }
}

/**
 * Gets the companies collection with the subcollection of the given country.
 *
 * The companies collection is filtered by the given country and the default flag is set to true.
 * The subcollection is then retrieved and mapped to an array of objects with the id and data of each document.
 *
 * @param {string} collectionName The name of the collection to get the companies from.
 * @param {string} subcollectionName The name of the subcollection to get the subcollection from.
 * @param {string} country The country to filter the companies by.
 * @returns {Promise<Array<{companyDetails: {id: string, [key: string]: any}, subCollection: Array<{id: string, [key: string]: any}>}>>} An array of objects with the company details and subcollection.
 * @throws {Error} If there is an error getting the companies collection.
 */
export const getCompanyCollection = async (collectionName, subcollectionName, country) => {
    try {
        const companyRef = collection(firestore, collectionName);
        const q = query(companyRef, where('country.country', '==', country), where('default', '==', true));
        const querySnapshot = await getDocs(q);
        const data = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const subCollectionRef = collection(doc.ref, subcollectionName);
                const subCollectionQuerySnapshot = await getDocs(subCollectionRef);
                const subCollection = subCollectionQuerySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                return {
                    companyDetails: { id: doc.id, ...doc.data() },
                    subCollection,
                };
            })
        );
        return data;
    } catch (e) {
        console.error('Error listing items:', e);
        throw e;
    }
};
