import useCartFoodLines from '@/hooks/useCartData';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import CartBag from '../icons/CartBag';
import CartBagWithoutLeg from '../icons/CartBagWithoutLeg';
const CartHeader = () => {
  const router = useRouter();
  const t = useTranslations('cart');
  const {
    cart,
    foodLines
  } = useCartFoodLines();
  const handleRedirect = () => {
    router.push('/cartitems');
  };
  return <button onClick={handleRedirect} className="cartIconwrap" data-sentry-component="CartHeader" data-sentry-source-file="CartHeader.jsx">
            <div className="cartIcon">
                <button>
                    {cart?.count > 0 && foodLines.length != 0 ? <>
                            <CartBag />
                            <span>{cart?.count}</span>
                        </> : <CartBagWithoutLeg />}
                </button>
            </div>
            {cart?.count > 0 && cart?.itemTotal && cart?.itemTotal !== 0 && foodLines.length != 0 && <h4>
                    {cart?.config?.currency?.currency}{' '}
                    {cart?.itemTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}{' '}
                    <small>
                        {cart?.count}x <span>{t('item_added')}</span>
                    </small>
                </h4>}
        </button>;
};
export default CartHeader;