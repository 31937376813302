import { ORDER_TYPES } from '@/configs/constants';

const AuthStatus = {
    invalidEmail: 'invalidEmail',
    weakPassword: 'weakPassword',
    wrongPassword: 'wrongPassword',
    emailAlreadyExists: 'emailAlreadyExists',
    userCancelled: 'userCancelled',
    channelError: 'channelError',
    userNotFound: 'userNotFound',
    invalidPhoneNumber: 'invalidPhoneNumber',
    appNotAuthorized: 'appNotAuthorized',
    invalidVerificationCode: 'invalidVerificationCode',
    missingClientIdentifier: 'missingClientIdentifier',
    tooManyRequests: 'too-may-otp-request-message',
};

/**
 * Converts Firebase auth error codes to internal AuthStatus enum
 * @param error Firebase auth error code
 * @returns Corresponding AuthStatus
 */
export const handleAuthException = (e) => {
    switch (e.code) {
        case 'auth/invalid-email':
            return AuthStatus.invalidEmail;
        case 'auth/wrong-password':
            return AuthStatus.wrongPassword;
        case 'auth/weak-password':
            return AuthStatus.weakPassword;
        case 'auth/email-already-in-use':
        case 'auth/account-exists-with-different-credential':
            return AuthStatus.emailAlreadyExists;
        case 'auth/web-context-canceled':
            return AuthStatus.userCancelled;
        case 'auth/user-not-found':
            return AuthStatus.userNotFound;
        case 'auth/channel-error':
            return AuthStatus.channelError;
        case 'auth/missing-client-identifier':
            return AuthStatus.missingClientIdentifier;
        case 'auth/too-may-otp-request-message':
        case 'auth/too-many-requests':
            return AuthStatus.tooManyRequests;
        case 'auth/invalid-verification-code':
            return AuthStatus.invalidVerificationCode;
        case 'auth/invalid-app-credential':
            return AuthStatus.appNotAuthorized;
        case 'auth/invalid-phone-number':
            return AuthStatus.invalidPhoneNumber;
        default:
            console.error('Unhandled auth error:', e);
            return AuthStatus.defaultError;
    }
};

/**
 * Generates user-friendly error messages based on AuthStatus
 * @param error AuthStatus enum value
 * @returns Localized error message
 */
export const generateErrorMessage = (error) => {
    switch (error) {
        case AuthStatus.invalidEmail:
            return 'authErrors.invalidEmail'.tr();
        case AuthStatus.weakPassword:
            return 'authErrors.weakPassword'.tr();
        case AuthStatus.wrongPassword:
            return 'authErrors.wrongPassword'.tr();
        case AuthStatus.emailAlreadyExists:
            return 'authErrors.emailAlreadyExists'.tr();
        case AuthStatus.userCancelled:
            return '';
        case AuthStatus.channelError:
            return 'authErrors.channelError'.tr();
        case AuthStatus.userNotFound:
            return "User with this email doesn't exist.";
        case AuthStatus.invalidPhoneNumber:
            return 'authErrors.invalidPhoneNumber'.tr();
        case AuthStatus.appNotAuthorized:
            return 'authErrors.appNotAuthorized'.tr();
        case AuthStatus.invalidVerificationCode:
            return 'authErrors.invalidVerificationCode'.tr();
        case AuthStatus.missingClientIdentifier:
            return 'authErrors.missingClientIdentifier'.tr();
        case AuthStatus.tooManyRequests:
            return 'authErrors.tooManyRequests'.tr();
        default:
            return 'authErrors.defaultError'.tr();
    }
};

// Type guard to check if a string is a valid AuthStatus
export const isAuthStatus = (value) => {
    return Object.values(AuthStatus).includes(value);
};

export const regexPatterns = {
    alphanumeric: /^[A-Za-z0-9\s]*$/,
    alphabetsOnly: /^[A-Za-z]*$/,
    numbersOnly: /^\d*$/,
    alphanumericNoSpaces: /^[A-Za-z0-9]*$/,
    alphabetsAndSpaces: /^[A-Za-z\s]*$/,
    alphabetsAndSpacesWithDots: /^[A-Za-z0-9 _.'-]*$/,
};

export const validateInput = (value, pattern) => {
    return pattern.test(value);
};

const getInputValue = (e) => {
    const input = e.target;

    if (e.type === 'beforeinput') {
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const proposed = e.data || '';

        return input.value.slice(0, start) + proposed + input.value.slice(end);
    }

    return input.value;
};

const handleInput = (e, pattern, action = 'preventInput') => {
    const proposedValue = getInputValue(e);
    const isValid = validateInput(proposedValue, pattern);

    switch (action) {
        case 'preventInput':
            if (!isValid) {
                e.preventDefault();
            }
            return isValid;

        case 'returnStatus':
            return {
                isValid,
                value: proposedValue,
                originalEvent: e,
                invalidChars: isValid
                    ? []
                    : proposedValue.match(new RegExp(`[^${pattern.source.slice(1, -1)}]`, 'g')) || [],
            };

        default:
            return isValid;
    }
};

const createInputHandler = (pattern) => {
    return (e, action = 'preventInput') => handleInput(e, pattern, action);
};

export const allowAlphanumericAndSpaces = createInputHandler(regexPatterns.alphanumeric);
export const allowAlphabetsOnly = createInputHandler(regexPatterns.alphabetsOnly);
export const allowNumbersOnly = createInputHandler(regexPatterns.numbersOnly);
export const allowAlphanumericNoSpaces = createInputHandler(regexPatterns.alphanumericNoSpaces);
export const allowAlphabetsAndSpaces = createInputHandler(regexPatterns.alphabetsAndSpaces);

export const isLocalTakeAway = (type) => {
    if (!type) return false;
    return type === ORDER_TYPES.TAKEAWAY;
};

export const isGlobalTakeAway = (type) => {
    if (!type) return false;
    return type === ORDER_TYPES.GLOBAL_TAKEAWAY;
};

export const isTakeAway = (type) => {
    if (!type) return false;
    return [ORDER_TYPES.TAKEAWAY, ORDER_TYPES.GLOBAL_TAKEAWAY].includes(type);
};

export const isGlobalOrder = (type = '') => {
    return type.includes('GLOBAL_');
};

export const isDelivery = (type) => {
    if (!type) return false;
    return [ORDER_TYPES.DELIVERY, ORDER_TYPES.GLOBAL_DELIVERY].includes(type);
};

export const validatePhoneNumber = (phoneNumber, country = {}) => {
    const { phoneMinLength } = country;
    let error = '';
    if (!phoneNumber) {
        error = `phoneRequired`;
    } else if (phoneNumber.length < phoneMinLength) {
        error = `invalidPhoneNumber`;
    }
    return { isValid: !error, error };
};

// ADDRESS FORM VALIDATION START
// Field configuration object
const FIELD_CONFIG = {
    name: { maxLength: 32, required: true },
    phone: { maxLength: 15, required: true },
    houseNumber: { maxLength: 32, required: true },
    directions: { maxLength: 100, required: true },
    apartment: { maxLength: 32, required: true },
    addressType: { maxLength: 32, required: true, conditionalCheck: (data) => !data.addressType },
};

// Error message keys
const ERROR_TYPES = {
    required: (fieldName) => `${fieldName}Required`,
    tooLong: (fieldName) => `${fieldName}TooLong`,
};

// Validate a single field based on rules
const validateSingleField = (fieldName, value, formData) => {
    const config = FIELD_CONFIG[fieldName];
    if (!config) return '';

    // Check if we should validate based on conditional check
    if (config.conditionalCheck && !config.conditionalCheck(formData)) {
        return '';
    }

    // Required check
    if (config.required && !value) {
        return ERROR_TYPES.required(fieldName);
    }

    // Length check
    if (value && value.length > config.maxLength) {
        return ERROR_TYPES.tooLong(fieldName);
    }

    return '';
};

// Main validation function
export const validateField = ({ name, value, errors = {}, formData }) => {
    const newErrors = { ...errors };

    if (FIELD_CONFIG[name]) {
        newErrors[name] = validateSingleField(name, value, formData);
    }

    return {
        isValid: !Object.values(newErrors).some((error) => error),
        errors: newErrors,
    };
};
// ADDRESS FORM VALIDATION END
