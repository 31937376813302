const unifiedAddressFormat = {
    address: {
        apartment: null,
        addressType: null,
        phone: null,
        directions: null,
        name: null,
        houseNumber: null,
        formatted: null,
        geoData: {
            coordinates: {
                lat: null,
                lng: null,
            },
            formattedAddress: null,
        },
    },
    plus_code: {
        compound_code: null,
        global_code: null,
    },
    place: {
        placeId: null,
        place: null,
        formatted: null,
        components: {
            main: null,
            secondary: null,
        },
    },
    metadata: {
        type: null,
        timestamp: null,
    },
};

function parseAddressResponse(response) {
    let result = JSON.parse(JSON.stringify(unifiedAddressFormat));

    if (response.plus_code) {
        result.plus_code.compound_code = response.plus_code.compound_code || null;
        result.plus_code.global_code = response.plus_code.global_code || null;
    }

    if (response.results && response.results.length > 0) {
        const addressResult = response.results[0];

        result.address.formattedAddress = addressResult.formatted_address || null;

        if (addressResult?.geometry?.location) {
            result.coordinates.lat = addressResult.geometry.location.lat || null;
            result.coordinates.lng = addressResult.geometry.location.lng || null;
        }

        result.place_id = addressResult.place_id || null;
        result.metadata.type = addressResult.types.join(', ') || null;
    }

    if (response.placeId) {
        result.place.placeId = response.placeId;
        result.place.place = response.place;
        result.place.formattedAddress = response.text?.text;
        result.place.components.main = response.structuredFormat?.mainText?.text;
        result.place.components.secondary = response.structuredFormat?.secondaryText?.text;
    }

    if (response.coordinates) {
        result.address.geoData.coordinates = { ...response.coordinates };
        result.address.geoData.formattedAddress = response.text.text || response.formattedAddress;
    }

    return result;
}

export { parseAddressResponse };
