import { addressSaveTypes, localStorageKeys, ORDER_TYPES } from '@/configs/constants';
import useCartFoodLines from '@/hooks/useCartData';
import useGeocode from '@/hooks/useGeocode';
import useLocalStorage from '@/hooks/useLocalStorage';
import useRecentSearches from '@/hooks/useRecentSearches';
import { getNearestStore } from '@/service/home.service';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { parseAddressResponse } from '../DataFormatter';
import { MODAL_MODES, MODAL_STEPS } from '../config';
import { useModalConfig } from './useModalConfig';

const useLocationForm = (initialState = {}) => {
    const router = useRouter();
    const confirmationModalTranslator = useTranslations('Common');
    const { mode = MODAL_MODES.searchLocation, type = ORDER_TYPES.DELIVERY, cartAddressUpdate } = router.query || {};

    const [formData, setFormData] = useState({
        addressSaveType: addressSaveTypes.TEMPORARY,
        isDataPopulated: false,
        ...initialState,
    });
    const [errors, setErrors] = useState({});
    const [shouldSearch, setShouldSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { geocode } = useGeocode();
    const { addSearch } = useRecentSearches('recentSearches');
    const { actions } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
    const { cart: cartData } = useCartFoodLines();
    const { customer, service } = cartData;

    const selectedOrderType = actions.getNestedValue(
        `${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`
    );

    const { goToNextStep } = useModalConfig(mode, type);

    const handleChange = useCallback(
        async (field, value) => {
            try {
                const keys = field.split('.');
                setFormData((prev) => {
                    const newFormData = { ...prev };
                    let current = newFormData;

                    keys.forEach((key, index) => {
                        if (index === keys.length - 1) {
                            current[key] = value;
                        } else {
                            current[key] = current[key] || {};
                            current = current[key];
                        }
                    });
                    return newFormData;
                });

                if (errors[field]) {
                    setErrors((prev) => {
                        const newErrors = { ...prev };
                        delete newErrors[field];
                        return newErrors;
                    });
                }
            } catch (error) {
                console.error('handleChange', error);
            }
        },
        [errors]
    );

    const handleSearchComplete = useCallback((newSuggestions) => {
        if (Array.isArray(newSuggestions)) {
            handleChange('suggestions', newSuggestions);
        }
    }, []);

    const handleSuggestionSelect = useCallback(
        async (suggestion) => {
            if (!suggestion) return;
            const response = await geocode({ place_id: suggestion.placeId });
            setShouldSearch(null);
            const formattedAddress = parseAddressResponse({
                ...suggestion,
                coordinates: response.geometry.location,
                formattedAddress: response.formatted_address,
            });
            handleChange('selectedPlace', formattedAddress);
            handleChange('suggestions', formattedAddress);
            addSearch({
                address: {
                    base: suggestion.text.text,
                    formatted: suggestion.formatted,
                },
                coordinates: response.geometry.location,
            });
            goToNextStep(cartAddressUpdate === 'true' ? MODAL_STEPS.SAVE_LOCATION : MODAL_STEPS.SELECT_ADDRESS);
        },
        [addSearch, setShouldSearch]
    );

    const validate = useCallback(() => {
        const newErrors = {};
        if (!formData.coordinates) {
            newErrors.coordinates = 'Location is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData]);

    const validateAndGetData = useCallback(() => {
        if (!validate()) return false;
        return formData;
    }, [formData, validate]);

    const getNearestStoreData = async ({ lat, lng }) => {
        try {
            const params = { lat, lng, type: selectedOrderType };
            const response = await getNearestStore(params);
            return response;
        } catch (error) {
            console.error('error', error);
        }
    };

    const resetFormData = () => {
        setFormData({
            addressSaveType: addressSaveTypes.TEMPORARY,
            isDataPopulated: false,
        });
        setShouldSearch(false);
    };

    const validateLocationChange = async (newOrderData) => {
        if (!newOrderData) {
            return {
                isValid: false,
            };
        }

        if (!customer?.customerId) {
            // if there is no cart
            return {
                isValid: true,
            };
        }

        const isAddressChanged = newOrderData.type !== service;
        const isLocationChanged =
            customer?.address?.geoData?.coordinates?.lat && customer?.address?.geoData?.coordinates?.lng
                ? newOrderData.coordinates?.lat !== customer?.address?.geoData?.coordinates?.lat ||
                  newOrderData.coordinates?.lng !== customer?.address?.geoData?.coordinates?.lng
                : false;
        const changedData = isAddressChanged ? 'Order type' : 'Location';

        return {
            isValid: !(isAddressChanged || isLocationChanged),
            modalConfig: {
                title: confirmationModalTranslator('confirmationModal.title', { changedData }),
                message: confirmationModalTranslator('confirmationModal.message', { changedData }),
                confirmText: confirmationModalTranslator('confirmationModal.confirmText', { changedData }),
                cancelText: confirmationModalTranslator('confirmationModal.cancelText', { changedData }),
            },
        };
    };

    return {
        formData,
        errors,
        setErrors,
        isLoading,
        setIsLoading,
        handleChange,
        validateAndGetData,
        setFormData,
        handleSearchComplete,
        handleSuggestionSelect,
        getNearestStoreData,
        resetFormData,
        shouldSearch,
        setShouldSearch,
        validateLocationChange,
    };
};

export default useLocationForm;
