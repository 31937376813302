import ConfirmationModal from '@/components/UI/popup';
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
const useConfirmationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [resolveRef, setResolveRef] = useState(null);
  const confirmAction = useCallback(({
    title,
    message,
    confirmText = 'Yes',
    cancelText = 'No'
  }) => {
    setModalProps({
      title,
      message,
      confirmText,
      cancelText
    });
    setIsOpen(true);
    return new Promise(resolve => {
      setResolveRef(() => resolve);
    });
  }, []);
  const handleConfirm = useCallback(() => {
    setIsOpen(false);
    resolveRef?.(true);
  }, [resolveRef]);
  const handleCancel = useCallback(() => {
    setIsOpen(false);
    resolveRef?.(false);
  }, [resolveRef]);
  let confirmationModal;
  if (isOpen) {
    confirmationModal = ReactDOM.createPortal(<ConfirmationModal {...modalProps} isOpen={isOpen} onConfirm={handleConfirm} onCancel={handleCancel} yes={modalProps.confirmText} no={modalProps.cancelText} onClose={handleCancel} />, document.body);
  }
  return {
    confirmAction,
    confirmationModal
  };
};
export default useConfirmationModal;