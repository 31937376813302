import { useEffect, useState } from 'react';
import { listenToCartFoodLines } from '@/service/cart';
import { useSelector } from 'react-redux';
const useCartFoodLines = () => {
  const authData = useSelector(store => store.auth).auth;
  const [foodLines, setFoodLines] = useState([]);
  const [cart, setCart] = useState({});
  const [loading, setLoading] = useState(true);
  let unsubscribe;
  const handleRefresh = async () => {
    if (unsubscribe) {
      unsubscribe(); // Unsubscribe the listener
    }
    setCart({});
    setFoodLines([]);
  };
  useEffect(() => {
    // Subscribe to Firestore updates
    unsubscribe = listenToCartFoodLines(authData.uid, data => {
      if (data.success) {
        setFoodLines(data?.foodLines || []);
        setCart(data?.cart || null);
      } else {
        // If data is not successful or the collection is removed, reset the state
        setFoodLines([]);
        setCart({});
      }
      setLoading(false);
    });

    // Cleanup the listener on component unmount
    return () => {
      unsubscribe();
      // Optionally reset state here if needed
      setFoodLines([]);
      setCart({});
    };
  }, [authData.uid]);
  return {
    foodLines,
    cart,
    loading,
    handleRefresh
  };
};
export default useCartFoodLines;