import { localStorageKeys } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import { getCountries } from '@/service/home.service';
import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import ReactSelect from 'react-select';

const CountrySelect = ({ value, onChange, error }) => {
    const t = useTranslations('LocationSelector');
    const [displayedOptions, setDisplayedOptions] = useState([]);

    const { actions } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
    const selectedCountry = actions.getNestedValue(localStorageKeys.SELECTED_COUNTRY);

    const getCountriesData = async () => {
        try {
            const response = await getCountries();
            let data = [];
            if (selectedCountry) {
                data = response.filter(
                    (country) => country.iso.toLowerCase() !== selectedCountry?.isoCode?.toLowerCase()
                );
            } else {
                data = response;
            }
            setDisplayedOptions(data);
        } catch (e) {
            console.error('Error listing countries:', e);
        }
    };

    const handleInputChange = useCallback(
        (val) => {
            try {
                if (!val || !displayedOptions.length) return;

                const filteredCountries = displayedOptions.filter((country) =>
                    country.name.common.toLowerCase().includes(val.toLowerCase())
                );
                setDisplayedOptions(filteredCountries);
            } catch (error) {
                console.error('Error filtering countries:', error);
            }
        },
        [displayedOptions]
    );

    useEffect(() => {
        getCountriesData();
    }, []);

    const options = displayedOptions
        .map((country) => {
            return {
                ...country,
                isoCode: country.iso,
            };
        })
        .map((country) => ({
            value: country.name,
            meta: country,
            label: (
                <div className="country-option" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <img src={country.image} alt={country.name} width={30} height={20} />
                    <span>{country.name}</span>
                </div>
            ),
        }));

    const selectedOption = options.find((option) => option.value === value?.name);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '8px',
            height: '44px',
            backgroundColor: '#fff',
            borderColor: state.isFocused ? '#e6e6e6' : provided.borderColor,
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            '&:hover': {
                borderColor: '#e6e6e6',
            },
        }),
    };
    return (
        <>
            <ReactSelect
                filterOption={(option) => option.value.toLowerCase() !== value?.name?.toLowerCase()}
                options={options}
                onChange={onChange}
                onInputChange={handleInputChange}
                value={selectedOption}
                placeholder={t('selectCountryLabel')}
                styles={customStyles}
            />
            {error && <span className="error">{error}</span>}
        </>
    );
};

export default CountrySelect;

CountrySelect.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    error: PropTypes.string,
};
