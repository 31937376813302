import useUrlParams from '@/hooks/useUrlParams';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
export const localeSwitchType = {
  TOGGLE: 'TOGGLE',
  SELECT: 'SELECT'
};
const LocaleSwitch = ({
  type = localeSwitchType.SELECT
}) => {
  const router = useRouter();
  const {
    locale,
    locales,
    asPath
  } = router;
  const {
    updateLocale
  } = useUrlParams();
  const t = useTranslations('Common');
  const [selected, setSelected] = useState(locale);
  const languageNames = {
    en: t('languages.english'),
    ar: t('languages.arabic')
  };
  const toggleSelected = () => {
    const newLocale = selected === 'ar' ? 'en' : 'ar';
    setSelected(newLocale);
  };
  useEffect(() => {
    setTimeout(() => {
      selected === 'ar' ? document.body.classList.add('rtlMode') : document.body.classList.remove('rtlMode');
      updateLocale(selected);
    }, 50);
  }, [selected]);
  if (type === localeSwitchType.TOGGLE) {
    return <button className="toggle-container languageSelector" onClick={toggleSelected} tabIndex={0}>
                <div className={`dialog-button`}>{selected.toUpperCase()}</div>
            </button>;
  }
  return <div className="language_wrap" data-sentry-component="LocaleSwitch" data-sentry-source-file="LocaleSwitch.jsx">
            {locales.map(l => <Link key={l} href={asPath} locale={l} legacyBehavior>
                    <button onClick={toggleSelected} className={locale === l ? 'active' : ''} aria-label={t('switchToLanguage', {
        language: languageNames[l]
      })}>
                        {l === 'ar' ? 'عربي' : l.toUpperCase()}
                    </button>
                </Link>)}
        </div>;
};
export default LocaleSwitch;
LocaleSwitch.propTypes = {
  type: PropTypes.string
};