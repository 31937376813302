const RefundPolicy = () => <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" data-sentry-element="svg" data-sentry-component="RefundPolicy" data-sentry-source-file="RefundPolicy.jsx">
        <g clipPath="url(#clip0_6358_9487)" data-sentry-element="g" data-sentry-source-file="RefundPolicy.jsx">
            <path d="M13.7213 7.69754C13.7213 11.4029 10.7066 14.4175 7.00125 14.4175C3.29587 14.4175 0.28125 11.4029 0.28125 7.69754C0.28125 7.46556 0.46913 7.27754 0.70125 7.27754C0.93337 7.27754 1.12125 7.46556 1.12125 7.69754C1.12125 10.9398 3.75899 13.5775 7.00125 13.5775C10.2435 13.5775 12.8813 10.9398 12.8813 7.69754C12.8813 4.45528 10.2435 1.81754 7.00125 1.81754C5.43871 1.81754 3.98761 2.425 2.89617 3.49754H4.48125C4.71337 3.49754 4.90125 3.68556 4.90125 3.91754C4.90125 4.14952 4.71337 4.33754 4.48125 4.33754H1.96125C1.72913 4.33754 1.54125 4.14952 1.54125 3.91754V1.39754C1.54125 1.16556 1.72913 0.977539 1.96125 0.977539C2.19337 0.977539 2.38125 1.16556 2.38125 1.39754V2.83016C3.62039 1.64744 5.24859 0.977539 7.00125 0.977539C10.7066 0.977539 13.7213 3.99216 13.7213 7.69754ZM7.42125 7.27754H6.58125C6.34955 7.27754 6.16125 7.0891 6.16125 6.85754C6.16125 6.62598 6.34955 6.43754 6.58125 6.43754H8.26125C8.49337 6.43754 8.68125 6.24952 8.68125 6.01754C8.68125 5.78556 8.49337 5.59754 8.26125 5.59754H7.42125V5.17754C7.42125 4.94556 7.23337 4.75754 7.00125 4.75754C6.76913 4.75754 6.58125 4.94556 6.58125 5.17754V5.59754C5.88643 5.59754 5.32125 6.16272 5.32125 6.85754C5.32125 7.55236 5.88643 8.11754 6.58125 8.11754H7.42125C7.65295 8.11754 7.84125 8.30598 7.84125 8.53754C7.84125 8.7691 7.65295 8.95754 7.42125 8.95754H5.74125C5.50913 8.95754 5.32125 9.14556 5.32125 9.37754C5.32125 9.60952 5.50913 9.79754 5.74125 9.79754H6.58125V10.2175C6.58125 10.4495 6.76913 10.6375 7.00125 10.6375C7.23337 10.6375 7.42125 10.4495 7.42125 10.2175V9.79754C8.11607 9.79754 8.68125 9.23236 8.68125 8.53754C8.68125 7.84272 8.11607 7.27754 7.42125 7.27754Z" fill="black" data-sentry-element="path" data-sentry-source-file="RefundPolicy.jsx" />
        </g>
        <defs data-sentry-element="defs" data-sentry-source-file="RefundPolicy.jsx">
            <clipPath id="clip0_6358_9487" data-sentry-element="clipPath" data-sentry-source-file="RefundPolicy.jsx">
                <rect width="14" height="14" fill="white" transform="translate(0 0.697266)" data-sentry-element="rect" data-sentry-source-file="RefundPolicy.jsx" />
            </clipPath>
        </defs>
    </svg>;
export default RefundPolicy;