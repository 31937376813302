import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { firebaseConfig } from '@/utils/constants/firebase';
import { useEffect, useState } from 'react';
import { initializeAppCheck, ReCaptchaV3Provider  } from "firebase/app-check";

const app = getApps()?.length ? getApp() : initializeApp(firebaseConfig);

export const auth = getAuth(app);


// Initialize App Check (client-side only)
if (typeof window !== 'undefined') {
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY),
        isTokenAutoRefreshEnabled: true,
    });
}


export function useFirebaseAuth() {
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);
        setAuth(auth);
    }, []);

    return auth;
}


export const firestore = getFirestore(app);
export const functions = getFunctions(app, process.env.NEXT_PUBLIC_FUNCTION_REGION || 'us-central1');
