import FavouriteCard from '@/components/FavouriteCard';
import SkeletonLoader from '@/components/loader/SkeltonLoader/SkeltonLoader';
import { getFavourites } from '@/service/favourite.service';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const MyFavourite = ({
  setShowFavouriteCard
}) => {
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authData = useSelector(store => store.auth).auth;
  useEffect(() => {
    fetchFavourites();
  }, [authData]);
  const fetchFavourites = async () => {
    const userId = authData?.uid;
    try {
      const data = await getFavourites({
        userId
      });
      setFavourites(data);
    } catch (error) {
      console.error('Failed to fetch favourites:', error);
      setError('Failed to load favourites');
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <SkeletonLoader />;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return <>
            <div className="overlay"></div>
            <section className="drawer">
                <FavouriteCard favourites={favourites} setShowFavouriteCard={setShowFavouriteCard} fetchFavourites={fetchFavourites} data-sentry-element="FavouriteCard" data-sentry-source-file="index.jsx" />
            </section>
        </>;
};
export default MyFavourite;
MyFavourite.propTypes = {
  setShowFavouriteCard: PropTypes.func.isRequired
};