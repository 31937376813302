/**
 * Utility function to create a product description string
 * for a cart item based on product details and selected modifiers.
 *
 * @param {Object} details - The product details object.
 * @param {Array} quantities - The array of quantities for each modifier group and its modifiers.
 * @returns {String} - The formatted product description string.
 */

export const createProductDescription = (details, quantities) => {
    // Start with the product name (replacing spaces with hyphens)
    let productDescription = details.id.toString();

    // Loop through modifier groups and their modifiers to append to productDescription
    if (details?.customizable === false) {
        details?.productModGroups?.forEach((modGroup, groupIndex) => {
            const selectedModifiers = [];

            modGroup.modifiers.forEach((modifier, modifierIndex) => {
                const quantity = quantities[groupIndex][modifierIndex];
                if (quantity > 0) {
                    // Add modifier name with quantity (replacing spaces and parentheses)
                    selectedModifiers.push(`${modifier.id}-${quantity}`);
                }
            });

            // Append selected modifiers to productDescription if any exist
            if (selectedModifiers.length > 0) {
                productDescription += `-with-${modGroup.id}:${selectedModifiers.join(',')}`;
            }
        });
    }

    return productDescription;
};

export const simplifyProductModGroups = (productModGroups, quantities, isMixed) => {
    return productModGroups?.map((group, groupIndex) => ({
        groupId: group.id, // Simplify to just the group ID
        posId: group.posId,
        groupName: group.modGroupName, // Keep the group name
        modifiers: group.modifiers
            .map((modifier, modifierIndex) => ({
                modifierId: modifier.id, // Keep just the modifier ID
                posId: modifier.posId,
                modifierName: modifier.modItemName, // Keep just the modifier name
                modifierPrice: modifier.price, // Keep the price
                quantity: quantities[groupIndex]?.[modifierIndex] || 0, // Add the quantity if present
                image: modifier.image,
            }))
            .filter((modifier) => modifier.quantity > 0),
        mixed: isMixed[groupIndex],
    }));
};
