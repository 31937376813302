// components/ConfirmationModal.js
import React from 'react';
import CloseIcon from '@/components/icons/CloseIcon';
import PropTypes from 'prop-types';
const ConfirmationModal = ({
  title,
  yes,
  no,
  message,
  onConfirm,
  onCancel,
  onClose
}) => {
  return <div id="myModal" className="chicking-modal" data-sentry-component="ConfirmationModal" data-sentry-source-file="popup.jsx">
            <div className="chicking-modal-wraper">
                <div className="chicking-modal-header">
                    <div className="chicking-modal-title">
                        <h4>{title}</h4>
                        <button onClick={onClose}>
                            <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="popup.jsx" />
                        </button>
                    </div>
                </div>
                <div className="chicking-modal-body">
                    <p>{message}</p>
                </div>
                <div className="chicking-modal-footer">
                    <div className="btn-group justify-end">
                        <button className="close-btn" onClick={onCancel}>
                            {no}
                        </button>
                        <button className="primary-btn" onClick={onConfirm}>
                            {yes}
                        </button>
                    </div>
                </div>
            </div>
        </div>;
};
export default ConfirmationModal;
ConfirmationModal.propTypes = {
  title: PropTypes.string,
  yes: PropTypes.string,
  no: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func
};