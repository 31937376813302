import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { ORDER_STATUS } from '@/configs/constants';
const OrderCard = ({
  orderNumber,
  orderDate,
  status,
  statusTime,
  setShowProfile
}) => {
  const router = useRouter();
  const t = useTranslations('orders');
  const navigateToOrderTracking = () => {
    router.push(`/order?orderNumber=${orderNumber}`);
    setShowProfile(false);
  };
  return <div className="order-id" data-sentry-component="OrderCard" data-sentry-source-file="OrderCard.jsx">
            <ul>
                <li>
                    <span>{t('order_number')}</span>
                    <span>{orderNumber}</span>
                </li>
                <li>
                    <span>{t('order_date')}</span>
                    <span>{orderDate}</span>
                </li>
                <li>
                    <span className={status === ORDER_STATUS.DELIVERED ? 'statusSuccess' : 'statusCancel'}>
                        {status}
                    </span>
                    <span className="statusTime">{statusTime}</span>
                </li>
                <li>
                    <button className="default_btn" onClick={navigateToOrderTracking}>
                        {t('more_info')}
                    </button>
                    {/* <button className="primary_btn">{t('repeat_order')}</button> */}
                </li>
            </ul>
        </div>;
};
OrderCard.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusTime: PropTypes.string.isRequired,
  setShowProfile: PropTypes.func.isRequired
};
export default OrderCard;