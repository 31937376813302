import StarIcon from '@/components/icons/StarIcon';
import { useAuth } from '@/hooks/useAuth';
import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Slider from 'react-slick';
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true
};
const Rating = ({
  rating
}) => {
  const stars = useMemo(() => Array.from({
    length: 5
  }, (_, i) => i < rating ? <StarIcon key={i} /> : null), [rating]);
  return <div className="ratting-wrap" data-sentry-component="Rating" data-sentry-source-file="TestimonialCarousel.jsx">
            <ul>{stars}</ul>
        </div>;
};
function TestimonialCarousel({
  data
}) {
  const t = useTranslations('testimonial');
  const {
    getLocaleContent
  } = useAuth();
  if (!data?.length) {
    return null;
  }
  return <Slider {...{
    ...settings,
    infinite: data.length > 1
  }} data-sentry-element="Slider" data-sentry-component="TestimonialCarousel" data-sentry-source-file="TestimonialCarousel.jsx">
            {data?.map(item => <div className="client-says-txt" key={item.id}>
                    <div className="client-says-header">
                        <h2>{t('client_say')}</h2>
                        <span>{getLocaleContent(item.feedbackTitle)}</span>
                    </div>
                    <div className="client-says-body">
                        <p>{getLocaleContent(item.feedback)}</p>
                        <div className="client-says-footer">
                            <div className="userDetails">
                                <h4>{getLocaleContent(item.title)}</h4>
                                <Rating rating={item.rating} />
                            </div>
                        </div>
                    </div>
                </div>)}
        </Slider>;
}
Rating.propTypes = {
  rating: PropTypes.number
};
TestimonialCarousel.propTypes = {
  data: PropTypes.array
};
export default TestimonialCarousel;