import DineinIcons from '@/components/icons/DineinIcons';
import PropTypes from 'prop-types';
const NoData = ({
  className = '',
  title = 'No Data',
  description = 'No data available',
  btnText = 'Explore',
  btnAction,
  hasIcon = true,
  icon
}) => {
  return <div className={`no-data-container ${className}`} data-sentry-component="NoData" data-sentry-source-file="index.jsx">
            <div className="no-data-content">
                <div className="no-data-body">
                    {hasIcon && (icon || <DineinIcons className="icon" />)}
                    <div className="text-block">
                        <h3>{title}</h3>
                        <p>{description}</p>
                        {btnAction && <button onClick={btnAction}>{btnText}</button>}
                    </div>
                </div>
            </div>
        </div>;
};
NoData.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  btnText: PropTypes.string,
  btnAction: PropTypes.func,
  hasIcon: PropTypes.bool,
  icon: PropTypes.element
};
export default NoData;