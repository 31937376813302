import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import SearchIcon from '../icons/SearchIcon';
const ToggleLabelInput = ({
  value,
  onChange,
  containerClassName,
  inputClassName,
  labelClassName,
  errorMessage,
  hasSearchIcon,
  placeholder
}) => {
  const [isEditing, setIsEditing] = useState(true);
  useEffect(() => {
    if (!isEditing) {
      setIsEditing(true);
    }
  }, [value]);
  const handleClick = () => {
    setIsEditing(true);
  };
  const handleBlur = () => {
    if (value) {
      setIsEditing(false);
    }
  };
  const handleChange = event => {
    onChange(event);
  };
  return <div className={containerClassName} data-sentry-component="ToggleLabelInput" data-sentry-source-file="index.jsx">
            {isEditing ? <input type="text" value={value} onChange={handleChange} onBlur={handleBlur} autoFocus className={inputClassName} placeholder={placeholder} /> : <button onClick={handleClick} className={labelClassName}>
                    {value}
                </button>}
            {hasSearchIcon && <SearchIcon />}
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>;
};
export default ToggleLabelInput;
ToggleLabelInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  hasSearchIcon: PropTypes.bool
};