import { modalTypeKey } from '@/configs/constants';
import useUrlParams from '@/hooks/useUrlParams';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Suspense, useEffect, useState } from 'react';
const modalComponents = {
  selectCountry: dynamic(() => import('@/components/modal/CountryModal'), {
    loading: () => <ModalSkeleton />,
    ssr: true
  }),
  selectOrderType: dynamic(() => import('@/components/modal/OrderTypeModal'), {
    loading: () => <ModalSkeleton />,
    ssr: true
  }),
  locationSelector: dynamic(() => import('@/components/LocationSelector'), {
    loading: () => <ModalSkeleton />,
    ssr: false
  }),
  productDetails: dynamic(() => import('@/pages/categories/components/CategoryDetails'), {
    loading: () => <ModalSkeleton />,
    ssr: false
  }),
  orderDetails: dynamic(() => import('@/components/Modal/OrderDetailsModal'), {
    loading: () => <ModalSkeleton />,
    ssr: false
  }),
  trackingOrderDetails: dynamic(() => import('@/components/Modal/OrderDetailsTrackingModal'), {
    loading: () => <ModalSkeleton />,
    ssr: false
  }),
  offerDetails: dynamic(() => import('@/components/OfferDetails/index'), {
    loading: () => <ModalSkeleton />,
    ssr: false
  }),
  paymentOptions: dynamic(() => import('@/components/Modal/PaymentOptionsModal'), {
    loading: () => <ModalSkeleton />,
    ssr: false
  })
};
export const modalTypes = Object.fromEntries(Object.keys(modalComponents).map(key => [key, key]));
const ModalWrapper = () => {
  const router = useRouter();
  const {
    getParam,
    removeParam,
    isReady
  } = useUrlParams();
  const [modalType, setModalType] = useState(null);
  useEffect(() => {
    if (isReady) {
      const queryModalType = router.query[modalTypeKey];
      setModalType(queryModalType);
    }
  }, [isReady, getParam, router.asPath]);
  useEffect(() => {
    const handleRouteChange = url => {
      const searchParams = new URLSearchParams(new URL(url, window.location.origin).search);
      const newModalType = searchParams.get(modalTypeKey);
      setModalType(newModalType);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  useEffect(() => {
    const handleEsc = e => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [closeModal]);
  useEffect(() => {
    if (modalType) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modalType]);
  function closeModal() {
    removeParam(modalTypeKey, {
      shallow: true
    });
  }
  if (!isReady || !modalType || !modalComponents[modalType]) {
    return null;
  }
  const ModalComponent = modalComponents[modalType];
  return <Suspense fallback={<ModalSkeleton />} data-sentry-element="Suspense" data-sentry-component="ModalWrapper" data-sentry-source-file="index.jsx">
            <ModalComponent isOpen={true} onClose={closeModal} data-sentry-element="ModalComponent" data-sentry-source-file="index.jsx" />
        </Suspense>;
};
export default ModalWrapper;
const ModalSkeleton = () => <div style={{
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000
}} data-sentry-component="ModalSkeleton" data-sentry-source-file="index.jsx">
        <div style={{
    width: '300px',
    height: '200px',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    animation: 'pulse 1.5s infinite ease-in-out'
  }}>
            <div style={{
      width: '100%',
      height: '1.3rem',
      backgroundColor: '#e0e0e0',
      marginBottom: '15px',
      borderRadius: '4px'
    }} />
            <div style={{
      width: '80%',
      height: '1rem',
      backgroundColor: '#e0e0e0',
      marginBottom: '10px',
      borderRadius: '4px'
    }} />
            <div style={{
      width: '80%',
      height: '1rem',
      backgroundColor: '#e0e0e0',
      marginBottom: '10px',
      borderRadius: '4px'
    }} />
            <div style={{
      width: '90%',
      height: '5rem',
      backgroundColor: '#e0e0e0',
      borderRadius: '4px'
    }} />
        </div>
        <style>{`
            @keyframes pulse {
                0% {
                    opacity: 0.6;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0.6;
                }
            }
        `}</style>
    </div>;