import PropTypes from 'prop-types';
import CloseIcon from '../icons/CloseIcon';
import Button from '../Button';
export const CommonModal = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  isOpen,
  closeIcon,
  isButtonLoader = false,
  isLoading = false
}) => {
  if (!isOpen) return null;
  return <div id="myModal" className="chicking-modal order-type-modal notifications-modal" data-sentry-component="CommonModal" data-sentry-source-file="CommonModal.jsx">
            <div className="chicking-modal-wraper">
                <div className="chicking-modal-header">
                    <div className="chicking-modal-title">
                        <h4>{title}</h4>
                        {!closeIcon && <button onClick={onCancel}>
                                <CloseIcon />
                            </button>}
                    </div>
                </div>
                <div className="chicking-modal-body">
                    <div>
                        <p>{message}</p>
                    </div>
                </div>
                <div className="chicking-modal-footer">
                    <div className="btn-group">
                        <button className="close-btn" onClick={onCancel}>
                            {cancelText}
                        </button>
                        {isButtonLoader ? <Button className="primary-btn" label={confirmText} isLoading={isLoading} onClick={onConfirm} disabled={isLoading} /> : <button className="primary-btn" onClick={onConfirm}>
                                {confirmText}
                            </button>}
                    </div>
                </div>
            </div>
        </div>;
};
CommonModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  isOpen: PropTypes.bool,
  closeIcon: PropTypes.bool,
  isButtonLoader: PropTypes.bool,
  isLoading: PropTypes.bool
};