import { HOME_INFO_TYPES } from '@/configs/constants';
import { Timestamp } from 'firebase/firestore';
import path from 'path';

const formatCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;
    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

    return formattedTime;
};

export const debugLog = ({ level = 'warn', message, params = {}, hasTimestamp = true }) => {
    if (process.env.NODE_ENV === 'development') {
        try {
            const stack = new Error().stack;
            const callerLine = stack?.split('\n')?.[2]?.trim() || '';

            let filePath = '';
            if (callerLine.includes('(')) {
                filePath = callerLine.substring(callerLine.indexOf('(') + 1, callerLine.lastIndexOf(':'));
            } else if (callerLine.includes('at')) {
                const atIndex = callerLine.indexOf('at') + 3;
                filePath = callerLine.substring(atIndex, callerLine.lastIndexOf(':')).trim();
            }

            const timestamp = hasTimestamp ? `[${formatCurrentTime()}]` : '';
            if (filePath) {
                const callerFile = path.basename(filePath.slice(0, 1024));
                const callerFolder = path.basename(path.dirname(filePath.slice(0, 1024)));

                const logPrefix = `${timestamp} [${callerFolder}/${callerFile}]`;
                const logMessage = `${logPrefix} ${message}`;

                switch (level.toLowerCase()) {
                    case 'warn':
                        console.warn(logMessage, params);
                        break;
                    case 'error':
                        console.error(logMessage, params);
                        break;
                    default:
                        console.warn(logMessage, params);
                        break;
                }
            } else {
                console.error(`[DebugLog]: Could not determine caller file path for message: "${message}"`, params);
            }
        } catch (error) {
            console.error('[DebugLog]: Error in logging logic.', error, { level, message, params });
        }
    }
};

export const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+?\d{7,15}$/;
    return phoneRegex.test(number);
};

export default async function loadMessages(locale) {
    try {
        return (await import(`@/lib/messages/langs/${locale}.json`)).default;
    } catch (error) {
        console.error('Error loading messages:', { ...error, locale });
        return {};
    }
}

export const currencyFormatter = (code, amount) => {
    const currency = {
        USD: '$',
        EUR: '€',
        GBP: '£',
        INR: '₹',
        UAE: 'AED',
    };
    return currency[code] ? `${currency[code]}${amount}` : `${amount} ${code}`;
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
};

export const calculateBiteCoinAmount = (loyalty, biteCoinConfig) => {
    return parseFloat(loyalty * (biteCoinConfig?.conversion || biteCoinConfig?.coinValue / 100)) || 0;
};
export const generateHomeData = (homeData, orderType) => {
    const homeDataObject = homeData.reduce((obj, item) => {
        const key = item.type;
        if (!obj[key]) obj[key] = [];

        const isAvailable = checkAvailability(item.type, item.data, orderType);
        if (isAvailable) obj[key].push(item.data);

        return obj;
    }, {});

    Object.keys(homeDataObject).forEach((key) => {
        homeDataObject[key].sort((a, b) => a.sortOrder - b.sortOrder);
    });

    return homeDataObject;
};

const checkAvailability = (type, data, orderType) => {
    switch (type) {
        case HOME_INFO_TYPES.CHEF_PICKS:
            return isTimedAvailable(data, orderType);
        case HOME_INFO_TYPES.BANNER:
            return isBannerAvailable(data);
        case HOME_INFO_TYPES.CATEGORY:
            return isTimedAvailable(data, orderType);
        case HOME_INFO_TYPES.TESTIMONIALS:
            return true; // Testimonials are always available
        default:
            return false;
    }
};
export const isTimedAvailable = (item, orderType) => {
    if (item?.isAvailableInStore === false) return false;
    if (!item?.services?.includes(orderType)) return false;

    // Non-timed items are always available
    if (!item?.timed) return true;

    // Check availability based on date and time
    const { start, end } = item?.availableDate || {};
    const now = new Date();

    // Convert start and end to Date objects if they are string representations of dates
    let startDate = null;
    let endDate = null;

    if (start) {
        startDate = typeof start === 'string' ? new Date(start) : formatFirebaseTimestamp(start);
    }

    if (end) {
        endDate = typeof end === 'string' ? new Date(end) : formatFirebaseTimestamp(end);
    }

    return item?.availableToday && startDate && endDate && startDate <= now && endDate >= now;
};

const isBannerAvailable = (banner) => {
    const now = new Date();
    const { startDate, endDate } = banner || {};
    const start = typeof startDate === 'string' ? new Date(startDate) : startDate?.toDate();
    const end = typeof endDate === 'string' ? new Date(endDate) : endDate?.toDate();
    return start <= now && end >= now;
};

export const isFalsy = (value) => {
    if (typeof value === 'function') {
        // Functions are always truthy
        return false;
    }

    if (Array.isArray(value)) {
        return value.length === 0;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    return !value;
};

export const toSentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const parseCoordinates = (coordinates = {}) => {
    if (isFalsy(coordinates)) {
        return {};
    }
    const { lat, lng, longitude, latitude } = coordinates;
    return {
        lat: latitude || lat || null,
        lng: longitude || lng || null,
    };
};

export const getStoreHours = (store) => {
    const { open, close } = store;

    const formatTime = (time) => {
        const [hours, minutes] = time.split(':');
        const ampm = parseInt(hours) >= 12 ? 'PM' : 'AM';
        const formattedHours = (parseInt(hours) % 12 || 12).toString().padStart(2, '0');
        return `${formattedHours}:${minutes} ${ampm}`;
    };

    return {
        open: (open && formatTime(open)) || 'Opening time not available',
        close: (close && formatTime(close)) || 'Closing time not available',
    };
};

export const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':').map(Number); // Split and convert to numbers
    const date = new Date();
    date.setHours(hours, minutes);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const formatFirebaseTimestamp = (timestamp) => new Date(timestamp.seconds * 1000);
export const transformTimestamps = (data, visited = new WeakSet()) => {
    if (data === null || data === undefined) return data;

    // If it's an instance of Timestamp, convert it to ISO string
    if (data instanceof Timestamp) {
        return data.toDate().toISOString();
    }

    // If it's an object, check if we've already visited this object to avoid circular references
    if (typeof data === 'object') {
        if (visited.has(data)) {
            return data; // Return the object as is to prevent infinite recursion
        }

        visited.add(data); // Mark this object as visited

        // If it's an array, recursively transform each item
        if (Array.isArray(data)) {
            return data.map((item) => transformTimestamps(item, visited));
        }

        // If it's an object, recursively transform each property
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, transformTimestamps(value, visited)])
        );
    }

    return data;
};

export const extractKeys = (obj = {}, keys = []) => {
    return keys.reduce((acc, key) => {
        if (obj[key] !== undefined) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};

export const getSliderSettings = ({ data = [], responsive }) => {
    const settings = {
        speed: 500,
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        cssEase: 'linear',
        arrows: data?.length < 6,
        infinite: data?.length > 6,
        autoplay: data?.length > 6,
        responsive: responsive || [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return settings;
};
