import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY;

const mapContainerStyle = {
    width: '100%',
    height: '20vh',
};

const LocationMap = ({ coordinates, onLocationChange, error, preventZoom, preventMarkerMovement }) => {
    const t = useTranslations('LocationSelector');
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: API_KEY,
    });
    const [markerPosition, setMarkerPosition] = useState(coordinates);

    useEffect(() => {
        setMarkerPosition(coordinates);
    }, [coordinates]);

    const handleMarkerDragEnd = (event) => {
        const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };
        setMarkerPosition(newLatLng);
        onLocationChange(newLatLng);
    };

    if (loadError) {
        return <div>{t('loadMapError')}</div>;
    }

    if (!isLoaded) {
        return <div>{t('mapLoading')}</div>;
    }
    let options = {};
    if (preventZoom) {
        options = {
            scrollwheel: false,
            zoomControl: false,
            disableDoubleClickZoom: true,
        };
    }

    return (
        <>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={markerPosition}
                zoom={19}
                options={{
                    disableDefaultUI: false,
                    draggable: !preventMarkerMovement,
                    gestureHandling: !preventMarkerMovement,
                    ...options,
                }}
            >
                {markerPosition && (
                    <MarkerF
                        position={markerPosition}
                        draggable={!preventMarkerMovement}
                        onDragEnd={handleMarkerDragEnd}
                    />
                )}
            </GoogleMap>

            {error && <span className="error">{error}</span>}
        </>
    );
};

export default LocationMap;

LocationMap.propTypes = {
    coordinates: PropTypes.object,
    onLocationChange: PropTypes.func,
    error: PropTypes.string,
    preventZoom: PropTypes.bool,
    preventMarkerMovement: PropTypes.bool,
};
