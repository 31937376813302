import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loaders: [],
    feedbacks: [],
    home: {},
    countries: [],
};

const commonSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        addLoader(state, action) {
            return {
                ...state,
                loaders: [...state.loaders, action.payload],
            };
        },
        removeLoader(state, action) {
            return {
                ...state,
                loaders: state.loaders.filter((loader) => loader !== action.payload),
            };
        },
        addHomeData(state, action) {
            return {
                ...state,
                home: { ...state.home, ...action.payload?.home },
                companyDetails: { ...state.companyDetails, ...action.payload?.companyDetails },
            };
        },
        addNearByStores(state, action) {
            return {
                ...state,
                nearByStores: action.payload,
            };
        },
        setCountries(state, action) {
            return {
                ...state,
                countries: action.payload,
            };
        },
        clearCountries(state) {
            return {
                ...state,
                countries: [],
            };
        },
    },
});

export const { addLoader, removeLoader, addHomeData, addNearByStores, setCountries, clearCountries } =
    commonSlice.actions;
export default commonSlice.reducer;
