import { auth } from '@/configs/firebase.config';
import { signOut } from 'firebase/auth';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

export const useAuth = () => {
    const { locale, defaultLocale } = useRouter();

    const getLocaleContent = (data = {}) => {
        return data[locale] || data[defaultLocale];
    };

    const logout = async () => {
        try {
            await signOut(auth);
            toast.success('User logged out successfully');
        } catch (error) {
            toast.error('Error logging out:', error);
        }
    };

    return { getLocaleContent, logout };
};
