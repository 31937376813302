const ChangeLanguage = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 22" fill="none" data-sentry-element="svg" data-sentry-component="ChangeLanguage" data-sentry-source-file="ChangeLanguage.jsx">
        <g clipPath="url(#clip0_2011_21585)" data-sentry-element="g" data-sentry-source-file="ChangeLanguage.jsx">
            <path d="M8.85145 10.9547C8.829 10.8424 8.05567 6.97568 8.03114 6.85315C7.99283 6.66144 7.82448 6.52344 7.62897 6.52344H6.80866C6.61315 6.52344 6.44479 6.66144 6.40648 6.85315C6.38157 6.97775 5.60744 10.8484 5.58617 10.9547C5.54174 11.1769 5.68581 11.3929 5.90792 11.4373C6.13004 11.4818 6.34614 11.3377 6.39054 11.1156L6.65271 9.80469H7.78491L8.04708 11.1156C8.09152 11.3378 8.30769 11.4818 8.5297 11.4374C8.75181 11.3929 8.89589 11.1769 8.85145 10.9547ZM6.81678 8.98438L7.1449 7.34375H7.29272L7.62085 8.98438H6.81678Z" fill="black" data-sentry-element="path" data-sentry-source-file="ChangeLanguage.jsx" />
            <path d="M15.4219 9.80469H14.1914V9.39453C14.1914 9.16802 14.0078 8.98438 13.7812 8.98438C13.5547 8.98438 13.3711 9.16802 13.3711 9.39453V9.80469H12.1406C11.9141 9.80469 11.7305 9.98833 11.7305 10.2148C11.7305 10.4414 11.9141 10.625 12.1406 10.625H12.2399C12.4736 11.38 12.8254 11.9595 13.2126 12.4239C12.8975 12.7122 12.5786 12.9486 12.2946 13.1758C12.1177 13.3173 12.089 13.5754 12.2305 13.7523C12.3721 13.9293 12.6302 13.9578 12.807 13.8163C13.0927 13.5878 13.435 13.3339 13.7812 13.0162C14.1277 13.3341 14.4707 13.5885 14.7555 13.8163C14.9324 13.9579 15.1905 13.9292 15.332 13.7523C15.4735 13.5754 15.4448 13.3173 15.2679 13.1758C14.9846 12.9491 14.6653 12.7125 14.3499 12.4239C14.7371 11.9595 15.0889 11.38 15.3226 10.625H15.4219C15.6484 10.625 15.832 10.4414 15.832 10.2148C15.832 9.98833 15.6484 9.80469 15.4219 9.80469ZM13.7812 11.8212C13.5194 11.4925 13.2839 11.1002 13.1063 10.6223H14.4561C14.2786 11.1002 14.0431 11.4925 13.7812 11.8212Z" fill="black" data-sentry-element="path" data-sentry-source-file="ChangeLanguage.jsx" />
            <path d="M16.2695 6.11328H10.3523L10.1765 4.70284C10.0997 4.08837 9.5748 3.625 8.95554 3.625H4.73047C4.05199 3.625 3.5 4.17699 3.5 4.85547V13.9062C3.5 14.5847 4.05199 15.1367 4.73047 15.1367H8.18899L8.36257 16.5472C8.43924 17.1604 8.96413 17.625 9.58355 17.625H16.2695C16.948 17.625 17.5 17.073 17.5 16.3945V7.34375C17.5 6.66527 16.948 6.11328 16.2695 6.11328ZM4.73047 14.3164C4.50431 14.3164 4.32031 14.1324 4.32031 13.9062V4.85547C4.32031 4.62931 4.50431 4.44531 4.73047 4.44531H8.95554C9.16196 4.44531 9.33693 4.59975 9.3625 4.80445C9.42195 5.28121 10.4893 13.8435 10.5482 14.3164H4.73047ZM9.15261 16.251L9.01548 15.1367H10.1159L9.15261 16.251ZM16.6797 16.3945C16.6797 16.6207 16.4957 16.8047 16.2695 16.8047H9.75827L11.3197 14.9986C11.3996 14.9084 11.4355 14.7887 11.4189 14.6698L10.4546 6.93359H16.2695C16.4957 6.93359 16.6797 7.11759 16.6797 7.34375V16.3945Z" fill="black" data-sentry-element="path" data-sentry-source-file="ChangeLanguage.jsx" />
        </g>
        <defs data-sentry-element="defs" data-sentry-source-file="ChangeLanguage.jsx">
            <clipPath id="clip0_2011_21585" data-sentry-element="clipPath" data-sentry-source-file="ChangeLanguage.jsx">
                <rect width="14" height="14" fill="black" transform="translate(3.5 3.625)" data-sentry-element="rect" data-sentry-source-file="ChangeLanguage.jsx" />
            </clipPath>
        </defs>
    </svg>;
export default ChangeLanguage;