import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
const useRecentSearches = (key, limit = 3) => {
  const [searches, setSearches] = useState([]);
  const {
    mode
  } = useRouter().query;
  useEffect(() => {
    if (!mode) return;
    const savedSearches = localStorage.getItem(key);
    if (savedSearches) {
      const data = JSON.parse(savedSearches) || {};
      setSearches(data[mode] || []);
    } else {
      setSearches([]);
    }
  }, [key, mode]);
  const addSearch = useCallback(newSearch => {
    if (!mode) return;
    const temp = {
      ...newSearch,
      mode
    };
    setSearches(prevSearches => {
      const updatedSearches = [temp, ...prevSearches.filter(search => search.address.base !== temp.address.base)].slice(0, limit);
      const savedSearches = localStorage.getItem(key);
      const allSearches = savedSearches ? JSON.parse(savedSearches) : {};
      allSearches[mode] = updatedSearches;
      localStorage.setItem(key, JSON.stringify(allSearches));
      return updatedSearches;
    });
  }, [key, limit, mode]);
  return {
    searches,
    addSearch
  };
};
export default useRecentSearches;