import { isFalsy } from '@/utils';
import { useCallback, useState } from 'react';
const useGeocode = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [geoCodeResponse, setGeoCodeResponse] = useState(null);
  const geocode = useCallback(async (params = {}) => {
    if (isFalsy(params)) return;
    setLoading(true);
    setError(null);
    try {
      let url = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}`;
      Object.keys(params).forEach(key => {
        url += `&${key}=${encodeURIComponent(params[key])}`;
      });
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.status === 'OK' && data.results.length > 0) {
        setGeoCodeResponse(data.results[0]);
        return data.results[0] || null;
      } else {
        setError(`Geocoding error: ${data.status}`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading,
    error,
    geocode,
    response: geoCodeResponse
  };
};
export default useGeocode;