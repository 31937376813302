import { modalTypes } from '@/components/ModalWrapper';
import { addressSaveTypes, localStorageKeys, ORDER_TYPES } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import { updateCurrentOrder } from '@/redux/slices/order';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AddressForm from '../AddressForm';
import CountrySelect from '../CountrySelect';
import GetDeviceLocation from '../GetDeviceLocation';
import LocationMap from '../LocationMap';
import PlaceSearch from '../PlaceSearch';
import RecentSearches from '../RecentSearches';
import SavedAddresses from '../SavedAddresses';
import { MODAL_MODES, MODAL_STEPS } from '../config';
import useLocationForm from '../hooks/useLocationForm';

const Delivery = ({
    formData,
    handleChange,
    errors,
    config,
    onClose,
    handleSearchComplete,
    handleSuggestionSelect,
    goToNextStep,
    handleBackClick,
    mode,
    validateField,
    handlePlaceSelect,
    updateCoordinates,
    handleAddNewAddress,
    currentStep,
    shouldSearch,
    setShouldSearch,
}) => {
    const router = useRouter();
    const t = useTranslations('LocationSelector');
    const dispatch = useDispatch();
    const { actions } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
    const { type = ORDER_TYPES.DELIVERY, modalType, cartAddressUpdate } = router.query;

    const { country, selectedPlace, suggestions = [], isDataPopulated } = formData;
    const { getNearestStoreData, validateLocationChange } = useLocationForm();
    const handleMarkerChange = async (coordinates) => {
        updateCoordinates(coordinates);
    };

    const handleRecentSearchSelect = (coordinates) => {
        updateCoordinates(coordinates);
        goToNextStep(MODAL_STEPS.CONFIRM_LOCATION);
    };

    const handleSavedAddressSelect = async (address) => {
        const nearestStoreData = await getNearestStoreData({
            lat: address?.address?.geoData?.coordinates?.lat,
            lng: address?.address?.geoData?.coordinates?.lng,
            type,
        });

        const formattedData = {
            type: type || ORDER_TYPES.DELIVERY,
            ...address,
            id: address.id,
            isPrimary: false,
            companyId: nearestStoreData?.data?.companyId,
            storeId: nearestStoreData?.data?.id,
            countryCode: nearestStoreData?.data?.companyId,
            outletCode: nearestStoreData?.data?.outletCode,
            companyData: {
                taxConfig: nearestStoreData?.data?.companyConfig?.taxConfig,
                currency: nearestStoreData?.data?.companyConfig?.currency,
                country: nearestStoreData?.data?.companyConfig?.country,
                deliveryConfig: nearestStoreData?.data?.storeConfig?.deliveryConfig,
                biteCoinConfig: nearestStoreData?.data?.companyConfig?.biteCoinConfig,
            },
        };

        dispatch(updateCurrentOrder(formattedData));
        actions.updateNestedValue(localStorageKeys.SELECTED_ADDRESS, formattedData);
        goToNextStep();
        onClose();
    };

    const handleDeviceLocation = (locationData) => {
        goToNextStep(MODAL_STEPS.CONFIRM_LOCATION);
        updateCoordinates(locationData.data);
    };

    const shouldShowAddNewButton = () => {
        return (
            config.addNewButton &&
            !selectedPlace &&
            formData.addressSaveType !== addressSaveTypes.NEW_ADDRESS &&
            [MODAL_STEPS.SELECT_LOCATION, MODAL_STEPS.SELECT_ADDRESS].includes(currentStep)
        );
    };

    const handleCountrySelect = (value) => {
        handleChange('selectedPlace', null);
        handleChange('country', value.meta);
    };

    const shouldShowSavedAddresses =
        !selectedPlace &&
        formData.addressSaveType !== addressSaveTypes.NEW_ADDRESS &&
        config.savedLocations &&
        !shouldSearch;

    const shouldShowPlaceSearch = () => {
        const isConfigActive = config.placeSearch.isActive;
        if (!isConfigActive) return false;
        if (modalType === modalTypes.locationSelector && mode === 'searchLocation') {
            return true;
        }
        if (mode === 'selectAddress' && cartAddressUpdate === 'true') {
            return currentStep === MODAL_STEPS.SEARCH_LOCATION;
        }
        return true;
    };
    return (
        <div className="location-wrap">
            <div className="location-block">
                <div className={mode !== MODAL_MODES.globalOrder ? '' : 'rowGroup'}>
                    {config.countrySelect && (
                        <div className="formGroup">
                            <label htmlFor="dob">{t('selectCountryLabel')}</label>
                            <CountrySelect value={country} onChange={handleCountrySelect} error={errors.country} />
                        </div>
                    )}
                    {shouldShowPlaceSearch() && (
                        <div className="formGroup">
                            {mode === MODAL_MODES.globalOrder && (
                                <label htmlFor="dob">{t('selectLocationLabel')}</label>
                            )}
                            <PlaceSearch
                                isInternalDropDown={config.placeSearch.isInternalDropDown}
                                country={country}
                                value={selectedPlace?.address?.geoData}
                                onChange={handlePlaceSelect}
                                error={errors.place}
                                onSearchComplete={handleSearchComplete}
                                isDataPopulated={isDataPopulated}
                                handleChange={handleChange}
                                shouldSearch={shouldSearch}
                                setShouldSearch={setShouldSearch}
                                goToNextStep={goToNextStep}
                            />
                        </div>
                    )}
                </div>
                {config.getDeviceLocation &&
                    formData.addressSaveType !== addressSaveTypes.NEW_ADDRESS &&
                    !selectedPlace?.coordinates && <GetDeviceLocation onSuccess={handleDeviceLocation} />}
                {shouldShowAddNewButton() && (
                    <button className="new-address-btn" onClick={handleAddNewAddress}>
                        {t('addNewAddress')}
                    </button>
                )}
            </div>
            {!config.placeSearch.isInternalDropDown && !isDataPopulated && shouldSearch && suggestions?.length > 0 && (
                <div className="recent-search common-list">
                    <h4>{t('searchResult')}</h4>
                    <div className="recent-search-list">
                        {suggestions.map((suggestion) => (
                            <button key={suggestion?.text?.text} onClick={() => handleSuggestionSelect(suggestion)}>
                                {suggestion?.text?.text}
                            </button>
                        ))}
                    </div>
                </div>
            )}
            {config.map && selectedPlace?.address?.geoData?.coordinates && !shouldSearch && (
                <div className="location-map">
                    <LocationMap
                        coordinates={selectedPlace?.address?.geoData?.coordinates}
                        onLocationChange={handleMarkerChange}
                        error={errors.coordinates}
                        preventZoom={mode === MODAL_MODES.globalOrder}
                        preventMarkerMovement={mode === MODAL_MODES.globalOrder}
                    />

                    <div className="delivery-address">
                        <h6>
                            {t('deliverTo')} -<span>{selectedPlace?.address?.geoData?.formattedAddress}</span>
                        </h6>
                        <button className="btn-change" onClick={handleBackClick}>
                            {t('change')}
                        </button>
                    </div>

                    {formData.addressSaveType === addressSaveTypes.NEW_ADDRESS && (
                        <AddressForm
                            currentAddress={formData}
                            handleFieldChange={handleChange}
                            errorsData={errors}
                            validateField={validateField}
                        />
                    )}
                </div>
            )}
            {shouldShowSavedAddresses && (
                <SavedAddresses
                    onSelect={handleSavedAddressSelect}
                    type={type}
                    onClose={onClose}
                    validateLocationChange={validateLocationChange}
                />
            )}
            {!selectedPlace &&
                config.recentSearches &&
                formData.addressSaveType !== addressSaveTypes.NEW_ADDRESS &&
                !shouldSearch && <RecentSearches onSelect={(place) => handleRecentSearchSelect(place?.coordinates)} />}
        </div>
    );
};

export default Delivery;

Delivery.propTypes = {
    mode: PropTypes.string,
    formData: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSearchComplete: PropTypes.func.isRequired,
    handleSuggestionSelect: PropTypes.func.isRequired,
    goToNextStep: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    validateField: PropTypes.func.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    handlePlaceSelect: PropTypes.func.isRequired,
    handleAddNewAddress: PropTypes.func.isRequired,
    updateCoordinates: PropTypes.func.isRequired,
    currentStep: PropTypes.any,
    shouldSearch: PropTypes.bool,
    setShouldSearch: PropTypes.func.isRequired,
};
