import useDeviceLocation from '@/hooks/useDeviceLocation';
import { parseCoordinates } from '@/utils';
import { useTranslations } from 'next-intl';
import { PropTypes } from 'prop-types';
import LocationSearching from '../icons/LocationSearching';
const GetDeviceLocation = ({
  onSuccess,
  onError
}) => {
  const {
    getLocation
  } = useDeviceLocation();
  const t = useTranslations('LocationSelector');
  const handleGetLocation = async () => {
    try {
      const result = await getLocation();
      if (onSuccess) {
        onSuccess({
          status: true,
          message: 'Location obtained',
          data: parseCoordinates(result?.data)
        });
      }
    } catch (error) {
      console.error('Failed to get location:', error.message);
      if (onError) {
        onError({
          status: false,
          message: 'Failed to get location',
          error
        });
      }
    }
  };
  return <button onClick={handleGetLocation} className="current-location-txt" data-sentry-component="GetDeviceLocation" data-sentry-source-file="GetDeviceLocation.jsx">
            <LocationSearching data-sentry-element="LocationSearching" data-sentry-source-file="GetDeviceLocation.jsx" /> {t('chooseCurrentLocation')}
        </button>;
};
export default GetDeviceLocation;
GetDeviceLocation.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};