import PropTypes from 'prop-types';
import ButtonLoader from '../loader/ButtonLoader';
import styles from './button.module.css';
const Button = ({
  children,
  label,
  type = 'button',
  disabled = false,
  isLoading = false,
  className = '',
  onClick,
  ...props
}) => {
  return <button type={type} disabled={disabled || isLoading} className={`${styles.btn} ${className}`} onClick={onClick} {...props} data-sentry-component="Button" data-sentry-source-file="index.jsx">
            {label ? <span>{label}</span> : children}
            {isLoading && <span className={styles['loader-container']}>
                    <ButtonLoader />
                </span>}
        </button>;
};
export default Button;
Button.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};