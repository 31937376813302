import { useEffect, useState } from "react";
import { MODAL_STEP_CONFIGS, MODAL_STEPS } from "../config";

export const useModalConfig = (mode, type, step = MODAL_STEPS.SELECT_LOCATION) => {
    const [currentStep, setCurrentStep] = useState(step);
    const [modalHistory, setModalHistory] = useState([MODAL_STEPS.SELECT_LOCATION]);

    const modeConfig = MODAL_STEP_CONFIGS[mode]?.[type];
    const currentConfig = modeConfig?.steps?.[currentStep] || {
        title: '',
        description: '',
        backButton: false,
        footer: { show: false }
    };

    useEffect(() => {
        const firstStep = getFirstStep();
        setCurrentStep(firstStep);
        setModalHistory([firstStep]);
    }, [mode, type]);

    const getPreviousStep = () => {
        if (!modeConfig?.steps) {
            return MODAL_STEPS.SELECT_LOCATION;
        }

        const stepKeys = Object.keys(modeConfig.steps);
        const currentIndex = stepKeys.indexOf(currentStep);

        if (currentIndex > 0) {
            return stepKeys[currentIndex - 1];
        }
        return stepKeys[0]; // Default to the first step if no previous step is valid
    };

    const getNextStep = () => {
        if (!modeConfig?.steps) {
            return currentStep; // Return the current step if no configuration exists
        }

        const stepKeys = Object.keys(modeConfig.steps);
        const currentIndex = stepKeys.indexOf(currentStep);

        if (currentIndex >= 0 && currentIndex < stepKeys.length - 1) {
            return stepKeys[currentIndex + 1];
        }
        return stepKeys[stepKeys.length - 1]; // Default to the last step if no next step is valid
    };

    const getFirstStep = () => {
        if (!modeConfig?.steps) {
            return MODAL_STEPS.SELECT_LOCATION;
        }

        return Object.keys(modeConfig.steps)[0]; // Always return the first step
    };

    const getMaxSteps = () => {
        return modeConfig?.maxSteps || 1;
    };

    const isValidStep = (step) => {
        if (!modeConfig?.steps) return false;
        return Object.keys(modeConfig.steps).includes(step);
    };

    const goToNextStep = (newStep) => {
        const nextStep = newStep || getNextStep();

        if (isValidStep(nextStep)) {
            setCurrentStep(nextStep);
            setModalHistory([...modalHistory, nextStep]);
        }
    };

    const goToPreviousStep = (newStep) => {
        let prevStep = newStep;

        if (!prevStep && modalHistory.length > 1) {
            prevStep = modalHistory[modalHistory.length - 2];
        } else if (!prevStep) {
            prevStep = getPreviousStep();
        }

        if (isValidStep(prevStep)) {
            const newHistory = modalHistory.slice(0, -1); // Remove the last step
            setModalHistory(newHistory);
            setCurrentStep(prevStep);
        }
    };

    const hasNextStep = () => {
        const stepKeys = Object.keys(modeConfig?.steps || {});
        const currentIndex = stepKeys.indexOf(currentStep);
        return currentIndex >= 0 && currentIndex < stepKeys.length - 1;
    };

    return {
        currentStep,
        currentConfig,
        goToNextStep,
        goToPreviousStep,
        getMaxSteps,
        hasNextStep: hasNextStep(),
        hasPreviousStep: modalHistory.length > 1,
    };
};
