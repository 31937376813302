import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';

import { PromoStatus } from '@/configs/constants';
import { firestore } from '@/configs/firebase.config';
import { callCloudFunction } from '@/lib/firebase/functions';
import { generateHomeData, transformTimestamps } from '@/utils';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import functions from '@/utils/constants/fb.functions';
/**
 * Get the home data for a given country and location.
 * If the location is not provided, the default company for the given country is used.
 * @param {string} country - The country to get the data for.
 * @param {string} [location] - The location to get the data for.
 * @returns {Promise<QueryDocumentSnapshot[]>} - The snapshot of the documents from the home subcollection.
 * @throws {Error} - If there is an error getting the data.
 */
export const getHomeData = async (country, orderDetails, orderType = 'DELIVERY') => {
    if (!country) return;
    try {
        if (!country) return;
        let ref;
        let companyId;
        let companyRef = collection(firestore, collections.companies);
        if (!orderDetails?.companyId) {
            const countryRef = doc(firestore, collections.countries, country);
            const docSnap = await getDoc(countryRef);
            if (docSnap.exists()) {
                companyId = String(docSnap.data()?.defaultCompanyId);
                ref = collection(companyRef, String(docSnap.data()?.defaultCompanyId), subCollections.home);
            }
        } else {
            ref = collection(
                firestore,
                collections.companies,
                String(orderDetails.companyId),
                subCollections.stores,
                String(orderDetails.storeId),
                subCollections.home
            );

            companyId = String(orderDetails?.companyId);
        }
        const homeQuery = query(
            ref,
            where('data.status', '==', PromoStatus.ACTIVE),
            orderBy('type'),
            orderBy('data.sortOrder')
        );
        const querySnapshot = await getDocs(homeQuery);
        const companyDetails = await getDoc(doc(firestore, collections.companies, companyId));

        const timestampUpdatedHomeData = transformTimestamps(
            generateHomeData(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })),
                orderType
            )
        );

        let testimonials = [];
        if (!timestampUpdatedHomeData?.TESTIMONIALS?.length) {
            const testimonialsRef = collection(
                firestore,
                `/${collections.companies}/${companyId}/${subCollections.testimonials}`
            );
            const testimonialsSnap = await getDocs(testimonialsRef);

            if (!testimonialsSnap.empty) {
                testimonials = testimonialsSnap.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
            }
        }

        const timestampUpdatedCompanyDetails = transformTimestamps(companyDetails.data());
        return {
            home: !timestampUpdatedHomeData?.TESTIMONIALS
                ? { ...timestampUpdatedHomeData, TESTIMONIALS: testimonials }
                : timestampUpdatedHomeData,
            companyDetails: timestampUpdatedCompanyDetails,
        };
    } catch (e) {
        console.error('Error listing items:', e);
        throw e;
    }
};

/**
 * Gets the products of a given store.
 *
 * @param {string} companyId The id of the company.
 * @param {string} storeId The id of the store.
 * @param {string} collectionName The name of the collection.
 * @param {string} subCollectionName The name of the subcollection.
 * @param {string} innerCollectionName The name of the inner collection.
 * @returns {Promise<Array<{id: string, [key: string]: any}>>} An array of objects with the id and data of each product.
 * @throws {Error} If there is an error getting the products.
 */
export const getStoreProducts = async (companyId, storeId, collectionName, subCollectionName, innerCollectionName) => {
    try {
        const productRef = collection(
            doc(firestore, `${collectionName}/${companyId}/${subCollectionName}/${storeId}`),
            innerCollectionName
        );
        const querySnapshot = await getDocs(productRef);
        const response = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return transformTimestamps(response);
    } catch (e) {
        console.error('Error listing items:', e);
        throw e;
    }
};

export const getNearByStores = async (request) => {
    try {
        const response = await callCloudFunction(functions.nearByStores, request);
        return transformTimestamps(response);
    } catch (error) {
        console.error('error in getNearByStores: ', error);
        throw error;
    }
};

export const getNearestStore = async (request) => {
    try {
        const response = await callCloudFunction(functions.getNearestStore, request);
        return transformTimestamps(response);
    } catch (error) {
        console.error('error in getNearestStore: ', error);
        throw error;
    }
};

export const getCountries = async () => {
    try {
        const countriesRef = collection(firestore, collections.countries);
        const sortedQuery = query(countriesRef, orderBy('sOrder', 'asc'));
        const querySnapshot = await getDocs(sortedQuery);

        // Map the documents into an array
        const countries = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        return countries;
    } catch (e) {
        console.error('Error listing countries:', e);
        throw e;
    }
};
export const getMasterCountries = async () => {
    try {
        const countriesRef = collection(firestore, collections.masterCountries);
        const sortedQuery = query(countriesRef, orderBy('name.en', 'asc'));
        const querySnapshot = await getDocs(sortedQuery);

        const masterCountries = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return masterCountries;
    } catch (e) {
        console.error('Error listing countries:', e);
        throw e;
    }
};

// firebase query to read all data from home collection if the
