import { API_URL } from '@/configs/constants';
import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { getAuth } from 'firebase/auth';
import Router from 'next/router';

const uploadLink = createUploadLink({
    uri: API_URL,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
            if (extensions.code === 'FORBIDDEN') {
                Router.replace('/auth/login');
            }
        });
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

const authLink = new ApolloLink(async (operation, forward) => {
    const token = await getAuth()?.currentUser?.accessToken;
    if (token) {
        operation.setContext({
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    return forward(operation);
});

const apolloClient = new ApolloClient({
    link: from([authLink, errorLink, uploadLink]),
    cache: new InMemoryCache(),
});

export default apolloClient;
