import OrderCard from '@/components/OrderCard/OrderCard';
import { dateToTimeFormat, viewDateFormat } from '@/lib/helpers/common';
import { wrapper } from '@/redux/store';
import { getOrderList } from '@/service/order.service';
import loadMessages from '@/utils';
import { useTranslations } from 'next-intl';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import ProfileHeader from '../profileHeader';
import { useSelector } from 'react-redux';
const Myorders = ({
  setShowProfile,
  showProfile,
  setShowMyOrders
}) => {
  const t = useTranslations('orders');
  const authData = useSelector(store => store.auth).auth;
  const [ordersData, setOrdersData] = useState([]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const getOrders = useCallback(async (startAfter = null) => {
    const response = await getOrderList({
      customerId: authData?.uid,
      limit: 10,
      startAfter
    });
    if (response) {
      setOrdersData(response.data);
    }
    setLastVisibleDoc(response?.lastVisible);
  }, [authData?.uid]);
  useEffect(() => {
    if (authData?.uid) {
      getOrders();
    }
  }, [authData?.uid, getOrders]);
  const loadMoreOrders = async () => {
    if (lastVisibleDoc && !isFetchingMore) {
      setIsFetchingMore(true);
      await getOrders(lastVisibleDoc);
      setIsFetchingMore(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      loadMoreOrders();
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreOrders]);
  return <>
            <div className="overlay"></div>
            <section className="drawer">
                <ProfileHeader setShowProfile={setShowProfile} showProfile={showProfile} backToProfile={() => setShowMyOrders(false)} title={t('my_orders')} data-sentry-element="ProfileHeader" data-sentry-source-file="index.jsx" />
                <ul className="orderCardList">
                    {ordersData?.map(order => <li key={order.id} className="list">
                            <OrderCard orderNumber={order.orderId} orderDate={viewDateFormat(order?.orderDate?.toDate?.())} status={order.orderStatus} statusTime={dateToTimeFormat(order?.orderUpdatedAt?.toDate?.())} setShowProfile={setShowProfile} />
                        </li>)}
                    {isFetchingMore && <li>Loading more...</li>}
                </ul>
            </section>
        </>;
};
export const getStaticProps = wrapper.getStaticProps(() => async ({
  locale
}) => {
  return {
    props: {
      locale,
      messages: await loadMessages(locale)
    }
  };
});
Myorders.propTypes = {
  setShowProfile: PropTypes.func.isRequired,
  showProfile: PropTypes.bool.isRequired,
  setShowMyOrders: PropTypes.func.isRequired
};
export default Myorders;