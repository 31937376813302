import { callCloudFunction } from '@/lib/firebase/functions';
import { getDocument } from '@/lib/firebase/query';
import { collections } from '@/utils/constants/fb.collections';
import functions from '@/utils/constants/fb.functions';
export const isNewUser = async ({ uid }) => {
    try {
        return await getDocument(collections.customers, uid);
    } catch (error) {
        console.error('error in isNewUser: ', error);
        throw error;
    }
};

export const createNewUser = async (request) => {
    try {
        return await callCloudFunction(functions.createCustomer, request);
    } catch (error) {
        console.error('error in createNewUser: ', error);
        throw error;
    }
};

export const updateUser = async (request) => {
    try {
        return await callCloudFunction(functions.updateCustomer, request);
    } catch (error) {
        console.error('error in updateUser: ', error);
        throw error;
    }
};

export const deleteUser = async (request) => {
    try {
        return await callCloudFunction(functions.deleteCustomer, request);
    } catch (error) {
        console.error('error in createNewUser: ', error);
        throw error;
    }
};
