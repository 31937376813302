import PropTypes from 'prop-types';
const Favourite = ({
  width,
  height
}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || '17'} height={height || '17'} viewBox="0 0 17 17" fill="none" data-sentry-element="svg" data-sentry-component="Favourite" data-sentry-source-file="Favourite.jsx">
        <path d="M14.3941 3.26205C14.0536 2.92139 13.6493 2.65115 13.2043 2.46677C12.7593 2.2824 12.2824 2.1875 11.8007 2.1875C11.3191 2.1875 10.8421 2.2824 10.3972 2.46677C9.95219 2.65115 9.5479 2.92139 9.2074 3.26205L8.50073 3.96872L7.79406 3.26205C7.10627 2.57426 6.17342 2.18786 5.20073 2.18786C4.22804 2.18786 3.29519 2.57426 2.6074 3.26205C1.9196 3.94985 1.5332 4.88269 1.5332 5.85538C1.5332 6.82807 1.9196 7.76092 2.6074 8.44872L3.31406 9.15538L8.50073 14.342L13.6874 9.15538L14.3941 8.44872C14.7347 8.10821 15.005 7.70393 15.1893 7.25895C15.3737 6.81398 15.4686 6.33704 15.4686 5.85538C15.4686 5.37373 15.3737 4.89679 15.1893 4.45181C15.005 4.00684 14.7347 3.60255 14.3941 3.26205Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Favourite.jsx" />
    </svg>;
export default Favourite;
Favourite.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};