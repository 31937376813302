import { firestore } from '@/configs/firebase.config';
import { collections, subCollections } from '@/utils/constants/fb.collections';
import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDocs,
    limit,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';

export async function favouriteDetails(productDetails, customerId) {
    try {
        await addToFavourites(productDetails, customerId);
        return true;
    } catch (error) {
        console.error('Error retrieving product details: ', error);
        return false;
    }
}

async function addToFavourites(productDetails, customerId) {
    try {
        if (!productDetails?.productId) {
            console.error('Invalid product details. Product ID is required.');
            return;
        }

        const customerRef = doc(firestore, collections.customers, customerId);

        await updateDoc(customerRef, {
            favourites: arrayUnion(productDetails.productId),
        });

        const favouritesCollectionRef = collection(customerRef, subCollections.favourites);

        const newFavouriteDocRef = doc(favouritesCollectionRef);
        const favouriteId = newFavouriteDocRef.id;

        const productData = {
            favouriteId,
            productId: productDetails.productId,
            customerId: productDetails.customerId,
            name: productDetails.name || '',
            image: productDetails.image || '',
            type: productDetails.type || '',
            addedAt: new Date(),
        };

        await setDoc(newFavouriteDocRef, productData);
    } catch (error) {
        console.error('Error adding product to favourites: ', error);
    }
}

export async function getFavourites({ userId, pageSize = 20 }) {
    if (!userId) {
        return { products: [] };
    }

    try {
        const userFavouritesRef = collection(firestore, collections.customers, userId, subCollections.favourites);

        let favouritesQuery = query(userFavouritesRef, limit(pageSize));

        const favouritesSnapshot = await getDocs(favouritesQuery);

        if (favouritesSnapshot.empty) {
            return [];
        }

        const productDetailsList = [];

        favouritesSnapshot.forEach((doc) => {
            const data = doc.data();
            const productDetails = {
                productId: data.productId,
                name: data.name,
                image: data.image,
                type: data.type,
            };
            productDetailsList.push(productDetails);
        });

        return productDetailsList;
    } catch (error) {
        console.error('Error fetching favourites: ', error);
        return [];
    }
}

export async function removeFromFavourites(productId, customerId) {
    try {
        const customerRef = doc(firestore, collections.customers, customerId);

        await updateDoc(customerRef, {
            favourites: arrayRemove(productId),
        });

        const favouritesCollectionRef = collection(customerRef, subCollections.favourites);

        const favQuery = query(favouritesCollectionRef, where('productId', '==', productId));
        const favSnapshot = await getDocs(favQuery);

        if (favSnapshot.empty) {
            return;
        }

        const favDoc = favSnapshot.docs[0];
        await deleteDoc(favDoc.ref);
        return true;
    } catch (error) {
        console.error('Error removing product from favourites:', error);
        return false;
    }
}

export function checkFavAdded(productId, favourites = []) {
    try {
        if (!favourites?.length) {
            return false;
        }
        console.warn('favourites', { favourites, productId });
        return favourites.includes(productId);
    } catch (error) {
        console.error('Error checking if product is in favourites:', error);
        return false;
    }
}
