import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { useDispatch, useSelector, useStore } from 'react-redux';

import accountReducer from '@/redux/slices/account';
import authReducer from '@/redux/slices/auth';
import cartReducer from '@/redux/slices/cart';
import commonReducer from '@/redux/slices/common';
import orderReducer from '@/redux/slices/order';
import storeReducer from '@/redux/slices/stores';

const initialState = {
    common: commonReducer(undefined, {}),
    account: accountReducer(undefined, {}),
    order: orderReducer(undefined, {}),
    auth: authReducer(undefined, {}),
    cart: cartReducer(undefined, {}),
    store: storeReducer(undefined, {}),
};

const appReducer = combineReducers({
    common: commonReducer,
    account: accountReducer,
    order: orderReducer,
    auth: authReducer,
    cart: cartReducer,
    store: storeReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        return initialState;
    }
    return appReducer(state, action);
};

export const makeStore = () => {
    return configureStore({
        reducer: rootReducer,
        devTools: true,
    });
};

export const wrapper = createWrapper(makeStore, { debug: false });

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export const useAppStore = useStore;

export const resetStore = (store) => {
    store.dispatch({ type: 'RESET_APP' });
};
