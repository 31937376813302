import styles from './loader.module.css';
const ButtonLoader = () => {
  return <div className={styles['lds-roller']} data-sentry-component="ButtonLoader" data-sentry-source-file="ButtonLoader.jsx">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>;
};
export default ButtonLoader;