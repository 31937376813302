import { useTranslations } from 'next-intl';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { modalTypeKey } from '@/configs/constants';
import { useAuth } from '@/hooks/useAuth';
import useUrlParams from '@/hooks/useUrlParams';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Location from '../icons/Location';
import { modalTypes } from '../ModalWrapper';
const settings = {
  dots: false,
  // infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [{
    breakpoint: 1199,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 2
    }
  }, {
    breakpoint: 767,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }]
};
function Outlets() {
  const t = useTranslations('Home');
  const {
    nearByStores = []
  } = useSelector(store => store.store);
  const {
    getLocaleContent
  } = useAuth();
  const {
    addMultipleParams
  } = useUrlParams();
  if (!nearByStores.length) return;
  const handleOrderNowClick = (coordinates, itemId) => {
    addMultipleParams({
      [modalTypeKey]: modalTypes.selectOrderType,
      redirect: 'categories',
      latlng: [...coordinates].reverse(),
      storeId: itemId
    });
  };
  const handleLocationClick = latlngArray => {
    // open google map in new tab
    window.open(`https://www.google.com/maps/search/?api=1&query=${latlngArray[0]},${latlngArray[1]}`, '_blank');
  };
  return <>
            <h4 className="">
                <small>{t('nearby')}</small> {t('outlets')}
            </h4>
            <p className="pb-50">{t('nearby_outlet_description')}</p>
            <Slider {...{
      ...settings,
      infinite: nearByStores?.length > 1,
      dots: false
    }} data-sentry-element="Slider" data-sentry-source-file="Outlets.jsx">
                {nearByStores?.map(item => <div className="outlets-items" key={item.id}>
                        <figure>
                            <img src={item.photo} alt="" />
                            <figcaption>
                                <h6>{getLocaleContent(item.name)}</h6>

                                <div className="btn-group">
                                    <button className="btn" onClick={() => handleOrderNowClick(item.location.coordinates, item.id)}>
                                        {t('order_now_button')}
                                    </button>
                                    <button className="btn-icon" onClick={() => handleLocationClick([...item.location.coordinates].reverse())}>
                                        <Location />
                                    </button>
                                </div>
                            </figcaption>
                        </figure>
                    </div>)}
            </Slider>
        </>;
}
export default Outlets;