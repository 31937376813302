import { localStorageKeys } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import { updateCurrentAccount } from '@/redux/slices/account';
import { updateAuthData } from '@/redux/slices/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useReverseGeocoding from './useReverseGeocoding';
const useDeviceLocation = () => {
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA, {});
  const {
    fetchAddresses
  } = useReverseGeocoding();
  const dispatch = useDispatch();
  const updateLocationStatus = useCallback(async (status, message, locationData = {}) => {
    let coordinates = {};
    if (locationData.data) {
      coordinates = {
        lat: locationData.data.latitude,
        lng: locationData.data.longitude
      };
    }
    const latlng = Object.values(coordinates).join(',');
    const addresses = await fetchAddresses([latlng]);
    const deviceLocation = {
      status,
      message,
      address: addresses?.[0]?.formatted_address,
      isLocationAllowed: locationData.isLocationAllowed,
      data: {
        ...coordinates
      }
    };
    actions.updateNestedValue(localStorageKeys.DEVICE_LOCATION, deviceLocation);
    dispatch(updateCurrentAccount({
      deviceLocation
    }));
    dispatch(updateAuthData({
      isDeviceLocationAllowed: true
    }));
  }, [actions]);
  const handleSuccess = useCallback(position => {
    const {
      latitude,
      longitude
    } = position.coords;
    updateLocationStatus(true, 'Location retrieved successfully', {
      isLocationAllowed: true,
      data: {
        latitude,
        longitude
      }
    });
    return {
      status: true,
      message: 'Success',
      data: {
        latitude,
        longitude
      }
    };
  }, [updateLocationStatus]);
  const handleError = useCallback(error => {
    const message = 'Location access denied by user';
    updateLocationStatus(false, message, {
      isLocationAllowed: false
    });
    console.error('Geolocation error:', {
      error
    });
    return {
      status: false,
      message
    };
  }, [updateLocationStatus]);
  const getLocation = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        const message = 'Geolocation is not supported by your browser';
        updateLocationStatus(false, message, {
          isLocationAllowed: false
        });
        reject(message);
      } else {
        navigator.geolocation.getCurrentPosition(position => resolve(handleSuccess(position)), error => reject(handleError(error)), {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        });
      }
    });
  }, [handleSuccess]);
  return {
    getLocation
  };
};
export default useDeviceLocation;