import { ORDER_TYPES } from "@/configs/constants";

export const LOCATION_MODES = {
    searchLocation: {
        [ORDER_TYPES.DELIVERY]: {
            tabs: true,
            countrySelect: false,
            addNewButton:true,
            getDeviceLocation:true,
            placeSearch: {
                isActive: true,
                isInternalDropDown:true
            },
            recentSearches: true,
            map: true,
            addressForm: true,
            savedLocations: true
        },
        [ORDER_TYPES.TAKEAWAY]: {
            tabs: true,
            getDeviceLocation: true,
            recentSearches: true,
            placeSearch: {
                isActive:true,
                isInternalDropDown:true
            },
            outletList: true,
            timeSelector: true
        }
    },
    globalOrder: {
        [ORDER_TYPES.GLOBAL_DELIVERY]: {
            tabs: true,
            countrySelect: true,
            addNewButton:false,
            getDeviceLocation:false,
            placeSearch: {
                isActive:true,
                isInternalDropDown:false
            },
            recentSearches: true,
            map: true,
            addressForm: false,
            savedLocations: false
        },
        [ORDER_TYPES.GLOBAL_TAKEAWAY]: {
            tabs: true,
            countrySelect: true,
            getDeviceLocation: false,
            placeSearch: {
                isActive:true,
                isInternalDropDown:false
            },
            recentSearches: true,
            map: true,
            outletList: true,
            timeSelector: true
        }
    },
    selectAddress: {
        [ORDER_TYPES.DELIVERY]: {
            tabs: false,
            countrySelect: false,
            addNewButton:true,
            getDeviceLocation:false,
            placeSearch: {
                isActive:true,
                isInternalDropDown:false
            },
            recentSearches: false,
            map: true,
            addressForm: true,
            savedLocations: true
        },
        [ORDER_TYPES.TAKEAWAY]: {
            tabs: false,
            getDeviceLocation: true,
            recentSearches: true,
            placeSearch: {
                isActive:true,
                isInternalDropDown:true
            },
            outletList: true,
            timeSelector: true
        },
        [ORDER_TYPES.GLOBAL_DELIVERY]: {
            tabs: false,
            countrySelect: true,
            addNewButton:false,
            getDeviceLocation:false,
            placeSearch: {
                isActive:true,
                isInternalDropDown:false
            },
            recentSearches: false,
            map: true,
            addressForm: false,
            savedLocations: false
        },
        [ORDER_TYPES.GLOBAL_TAKEAWAY]: {
            tabs: true,
            countrySelect: true,
            getDeviceLocation: false,
            placeSearch: {
                isActive:true,
                isInternalDropDown:false
            },
            recentSearches: false,
            map: true,
            outletList: true,
            timeSelector: true
        }
    },
};

export const STORAGE_KEY = 'locationSelectorState';

export const MODAL_MODES = {
    searchLocation: 'searchLocation',
    globalOrder: 'globalOrder',
}

export const MODAL_STEPS = {
    SELECT_LOCATION: 'SELECT_LOCATION',
    SEARCH_LOCATION: 'SEARCH_LOCATION',
    CONFIRM_LOCATION: 'CONFIRM_LOCATION',
    SAVE_LOCATION: 'SAVE_LOCATION',
    SELECT_ADDRESS: 'SELECT_ADDRESS',
}

export const MODAL_ACTIONS = {
    NEXT: 'NEXT',
    PREVIOUS: 'PREVIOUS',
    RESET: 'RESET',
    SET: 'SET'
};

export const MODAL_STEP_CONFIGS = {
    searchLocation: {
        [ORDER_TYPES.DELIVERY]: {
            maxSteps: 4,
            steps: {
                [MODAL_STEPS.SELECT_LOCATION]: {
                    title: 'selectLocationLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: false,
                        isSubmit: false
                    }
                },
                [MODAL_STEPS.SEARCH_LOCATION]: {
                    title: 'searchLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: false,
                        isSubmit: false
                    }
                },
                [MODAL_STEPS.CONFIRM_LOCATION]: {
                    title: 'confirmLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'confirmLocation',
                    }
                },
                [MODAL_STEPS.SAVE_LOCATION]: {
                    title: 'saveLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'saveLocation',
                    }
                }
            }
        },
        [ORDER_TYPES.TAKEAWAY]: {
            maxSteps: 2,
            steps: {
                [MODAL_STEPS.SELECT_LOCATION]: {
                    title: 'searchLocation',
                    ss:444,
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: false,
                        label:'order_now_enjoy_button'
                    }
                },
                [MODAL_STEPS.SEARCH_LOCATION]: {
                    title: 'searchLocation',
                    description: 'description',
                    ss:5641+6,
                    backButton: true,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label:'order_now_enjoy_button'
                    }
                },
                [MODAL_STEPS.SAVE_LOCATION]: {
                    title: 'saveLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'saveLocation',
                    }
                }
            }
        }
    },
    globalOrder: {
        [ORDER_TYPES.GLOBAL_DELIVERY]: {
            maxSteps: 2,
            steps: {
                [MODAL_STEPS.SELECT_LOCATION]: {
                    title: 'globalOrderDelivery',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'confirmLocation',
                    }
                },
                [MODAL_STEPS.CONFIRM_LOCATION]: {
                    title: 'globalOrderDelivery',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'confirmLocation',
                    }
                }
            }
        },
        [ORDER_TYPES.GLOBAL_TAKEAWAY]: {
            maxSteps: 3,
            steps: {
                [MODAL_STEPS.SELECT_LOCATION]: {
                    title: 'globalOrderTakeaway',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: false
                    }
                },
                [MODAL_STEPS.SEARCH_LOCATION]: {
                    title: 'globalOrderTakeaway',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: false,
                        label:'order_now_enjoy_button'
                    }
                },
                [MODAL_STEPS.CONFIRM_LOCATION]: {
                    title: 'globalOrderTakeaway',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label:'order_now_enjoy_button'
                    }
                }
            }
        }
    },
    selectAddress: {
        [ORDER_TYPES.DELIVERY]: {
            maxSteps: 1,
            steps: {
                [MODAL_STEPS.SELECT_ADDRESS]: {
                    title: 'selectAddressLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label:'order_now_enjoy_button'
                    }
                },
                [MODAL_STEPS.SEARCH_LOCATION]: {
                    title: 'searchLocation',
                    description: 'description',
                    backButton: true,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label: 'saveLocation',
                    }
                },
            }
        },
        [ORDER_TYPES.TAKEAWAY]: {
            maxSteps: 1,
            steps: {
                [MODAL_STEPS.SELECT_ADDRESS]: {
                    title: 'selectAddressLabel',
                    description: 'description',
                    backButton: false,
                    footer: {
                        show: true,
                        isSubmit: true,
                        label:'order_now_enjoy_button'
                    }
                }
            }
        }
    },
};
