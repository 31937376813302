import { getMasterCountries } from '@/service/home.service';
import { validatePhoneNumber } from '@/utils/validations';
import { useTranslations } from 'next-intl';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import PhoneNumberSelect from '../PhoneNumberSelect';
export const addressTypes = ['home', 'work', 'friendsAndFamily', 'other'];
const AddressForm = ({
  currentAddress,
  handleFieldChange,
  errorsData,
  validateField,
  isNewAddress = true
}) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    houseNumber: '',
    apartment: '',
    directions: '',
    addressType: 'other'
  });
  const t = useTranslations();
  const tError = useTranslations('ErrorMessages');
  const [errors, setErrors] = useState({});
  const [countriesData, setCountriesData] = useState([]);
  const [isOther, setIsOther] = useState(false);
  const [isNewAddressState, setIsNewAddressState] = useState(isNewAddress);
  useEffect(() => {
    getMasterCountriesData();
  }, []);
  const getMasterCountriesData = async () => {
    try {
      const response = await getMasterCountries();
      setCountriesData(response);
    } catch (e) {
      console.error('Error listing countries:', e);
    }
  };
  useEffect(() => {
    if (currentAddress) {
      setFormData({
        ...currentAddress
      });
      if (isNewAddressState) {
        setIsOther(false);
      } else {
        setIsOther(!(addressTypes.includes(formData?.addressType) && formData?.addressType !== 'other'));
      }
    }
  }, [currentAddress, isOther, formData?.addressType]);
  useEffect(() => {
    setErrors(errorsData);
  }, [errorsData]);
  const handleChange = e => {
    const {
      name,
      value
    } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    const fieldStatus = validateField(name, value);
    setErrors({
      ...errors,
      ...fieldStatus.errors
    });
    if (name === 'phone') {
      handleFieldChange(name, value?.phoneNumber);
    } else {
      handleFieldChange(name, value);
    }
  };
  const handleAddressTypeChange = selectedType => {
    setIsNewAddressState(false);
    const isOther = selectedType === 'other';
    setIsOther(isOther);
    handleChange({
      target: {
        name: 'addressType',
        value: selectedType
      }
    });
  };
  const handleCustomAddressType = e => {
    handleChange({
      target: {
        name: 'addressType',
        value: e.target.value
      }
    });
  };
  return <div className="location-map" data-sentry-component="AddressForm" data-sentry-source-file="AddressForm.jsx">
            <div className="form-block">
                <div className="rowGroup">
                    <div className="formGroup">
                        <label htmlFor="name">{t('profile.recieverNameLabel')}</label>
                        <input type="text" className="formControl" placeholder={t('profile.recieverNameLabel')} name="name" id="name" value={formData.name} onChange={handleChange} />

                        {errors.name && <span className="error-message">{t(`ErrorMessages.${errors.name}`)}</span>}
                    </div>
                    <div className="formGroup">
                        <label htmlFor="phone-input">{t('profile.phoneNumberLabel')}</label>
                        <PhoneNumberSelect id="phone-input" placeholder={t('profile.phoneNumberLabel')} value={formData.phone?.phoneNumber || formData.phone} countryListData={countriesData} onChange={val => handleChange({
            target: {
              name: 'phone',
              value: val
            }
          })} isSearchable={false} shouldShowPhoneCode={false} validatePhoneNumber={validatePhoneNumber} showError t={tError} classNames={['location-address-form']} shouldShowCountryName={false} data-sentry-element="PhoneNumberSelect" data-sentry-source-file="AddressForm.jsx" />
                        {errors.phone && <span className="error-message">{t(`ErrorMessages.${errors.phone}`)}</span>}
                    </div>
                </div>
                <div className="rowGroup">
                    <div className="formGroup">
                        <label htmlFor="apartment">{t('profile.apartmentRoadAreaLabel')}</label>
                        <input type="text" id="apartment" name="apartment" className="formControl" placeholder={t('profile.apartmentRoadAreaLabel')} value={formData.apartment} onChange={handleChange} />
                        {errors.apartment && <span className="error-message">{t(`ErrorMessages.${errors.apartment}`)}</span>}
                    </div>
                    <div className="formGroup">
                        <label htmlFor="houseNumber">{t('profile.houseFlatBlockNoLabel')}</label>
                        <input type="text" className="formControl" placeholder={t('profile.houseFlatBlockNoLabel')} name="houseNumber" id="houseNumber" value={formData.houseNumber} onChange={handleChange} />
                        {errors.houseNumber && <span className="error-message">{t(`ErrorMessages.${errors.houseNumber}`)}</span>}
                    </div>
                </div>
                <div className="formGroup">
                    <label htmlFor="directions">{t('profile.directionsToReachLabel')}</label>
                    <input type="text" name="directions" id="directions" className="formControl" placeholder={t('profile.directionsToReachLabel')} value={formData.directions} onChange={handleChange} />
                    {errors.directions && <span className="error-message">{t(`ErrorMessages.${errors.directions}`)}</span>}
                </div>
                <div className="save-list-wrap">
                    <h4>{t('profile.saveAs')}</h4>
                    <div className="save-list">
                        {addressTypes.map(item => {
            const isActive = !isNewAddressState && (item === formData.addressType || item === 'other' && !addressTypes.includes(formData?.addressType));
            return <button className={`address-type-btn ${isActive ? 'is-active' : ''}`} key={item} value={t('profile.' + item)} onClick={() => handleAddressTypeChange(item)}>
                                    {t('profile.' + item)}
                                </button>;
          })}
                        {isOther && <div className="formGroup">
                                <label htmlFor="addressType">{t('profile.addressTypeLabel')}</label>
                                <input type="text" name="addressType" id="addressType" className="formControl" placeholder="..." value={addressTypes.includes(formData.addressType) ? '' : formData.addressType} onChange={handleCustomAddressType} />
                                {errors.addressType && <span className="error-message">{t(`ErrorMessages.${errors.addressType}`)}</span>}
                            </div>}
                    </div>
                </div>
            </div>
        </div>;
};
export default AddressForm;
AddressForm.propTypes = {
  validateField: PropTypes.func,
  handleFieldChange: PropTypes.func,
  currentAddress: PropTypes.object,
  errorsData: PropTypes.object,
  isNewAddress: PropTypes.bool
};