import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styles from './loader.module.css';
const Loader = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false); // default to false
  const {
    modalType
  } = router.query;
  useEffect(() => {
    const start = () => {
      setIsLoading(true);
    };
    const end = () => {
      setIsLoading(false);
    };
    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
    };
  }, [router]);
  if (!isLoading) {
    return null;
  }
  if (['selectCountry', 'selectOrderType'].includes(modalType)) {
    return null;
  }
  return <div className={styles['loader-container']} data-sentry-component="Loader" data-sentry-source-file="index.jsx">
            <div className={styles['lds-ripple']}>
                <div></div>
                <div></div>
            </div>
        </div>;
};
export default Loader;