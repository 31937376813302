import { localStorageKeys } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import { isGlobalOrder } from '@/utils/validations';
import PropTypes from 'prop-types';
import Testimonial from '../Testimonial/Testimonial';
import Outlets from '../carousel/Outlets';
import CommonFooter from './CommonFooter';
const Footer = ({
  locale = 'en'
}) => {
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const orderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
  return <>
            {!isGlobalOrder(orderType) && <div className="outlets pt-50">
                    <div className="container">{<Outlets locale={locale} />}</div>
                </div>}
            <div className="client-says pt-50">
                <div className="container">
                    <Testimonial locale={locale} data-sentry-element="Testimonial" data-sentry-source-file="Footer.jsx" />
                </div>
            </div>
            <CommonFooter data-sentry-element="CommonFooter" data-sentry-source-file="Footer.jsx" />
        </>;
};
Footer.propTypes = {
  locale: PropTypes.string
};
export default Footer;