import axios from 'axios';
import { useEffect, useState } from 'react';
const useReverseGeocoding = (latlng = '') => {
  const [addresses, setAddresses] = useState([]);
  const [error, setError] = useState(null);
  const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY;
  const fetchAddresses = async latlngArray => {
    if (!latlngArray || latlngArray.filter(i => i).length === 0) return;
    try {
      const addressPromises = latlngArray.map(async latlng => {
        const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            latlng,
            key: API_KEY
          }
        });
        if (response.data.status === 'OK') {
          return response.data.results[0] || null;
        } else {
          return response.data.status;
        }
      });
      const addresses = await Promise.all(addressPromises);
      setAddresses(addresses);
      setError(null);
      return addresses;
    } catch (err) {
      setError(err);
      setAddresses([]);
    }
  };
  useEffect(() => {
    const latlngArray = Array.isArray(latlng) ? latlng : [latlng];
    fetchAddresses(latlngArray);
  }, [latlng]);
  return {
    addresses,
    error,
    fetchAddresses
  };
};
export default useReverseGeocoding;